import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    Grid,
    FormControl,
    FormControlLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    RadioProps,
    Select,
    TextField,
    Dialog,
    DialogContent,
    IconButton,
    DialogTitle,Divider
} from "@mui/material";
import {  Close as CloseIcon } from "@mui/icons-material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {
    styled,
    
  } from "@mui/material/styles";
  import { View,  } from "react-native";
  import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { filterIcon } from "../../../blocks/maps/src/assets";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DatePicker from 'react-datepicker';
  function BpRadio(props: RadioProps) {
    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
// Customizable Area End

import ReferralsController, { Props } from "./ReferralsController.web";



export default class Referrals extends ReferralsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    services = [
      { value: "Acacia Network", organization_name: "Acacia Network",path:"CustomForm" },
      { value: "Samaritan Daytop Village", organization_name: "Samaritan Daytop Village",path:"HarmReductionForm" },
      { value: "referrals_and_warm_hand_offs", organization_name: "VIP Community Services",path:"HealthcareAccessForm" },
      { value: "performed_and_facilitated_care", organization_name: "Performed and Facilitated Care",path:"AdministratedServices"},
  ];
  renderResultModal = () => (
    <Dialog
      open={this.state.successModal}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <Typography sx={webStyles.modalHeading}>
        {this.state.Title}
        </Typography>
        <IconButton  data-test-id="renderresultModal"
          onClick={()=>this.setState({successModal:false})}
          sx={{ position: 'absolute', right: 8, top: 2,fill:"#2D2A24" }}
        >
          <CloseIcon sx={{fill:"#2D2A24"}}/>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center', paddingY: 4 }}>
      {this.state.success? <CheckCircleOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "#059669",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> 
          :<ErrorOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "red",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> }
        <Typography variant="h6" sx={styles.arrowIcon}>
        {this.state.subTitle}
        </Typography>
      </DialogContent>
    </Dialog>
  )
  renderDate(){
    return(
      <Typography sx={{textAlign:"left",fontSize:"12px"}}>  
      {this.formatDate(this.state.selectedReferral.attributes.updated_at?this.state.selectedReferral.attributes.updated_at:this.state.selectedReferral.attributes.created_at)}
      </Typography>
    )
  }
  renderModal = () => (
    <Dialog
      open={this.state.openUpdateModal}
      onClose={()=>this.setState({openUpdateModal:false})}
      data-test-id="renderModal"
      maxWidth="xs"
      fullWidth
      
    >
      <DialogTitle sx={{backgroundColor:"#F6F6F3"}}>
        <Typography sx={webStyles.modalHeading}>
        Referral Information
        </Typography>
        <Typography sx={{textAlign:"left",
          fontSize:"12px"}}>

       {this.state.selectedReferral.attributes.sub_service_name}
        </Typography>
        
        <Grid container flexDirection={"row"}>
            <Grid item>
            <Typography sx={{fontWeight:"bold",fontSize:"12px",color:"#2D2A24",marginRight:1}}> 
              {this.state.selectedReferral?.attributes?.status.charAt(0).toUpperCase() + this.state.selectedReferral?.attributes?.status.slice(1)}
                </Typography>
            </Grid>
            <Grid item>
            {this.renderDate()}
            </Grid>
        </Grid>
        <IconButton data-test-id="renderModalbtn"
          onClick={()=>this.setState({openUpdateModal:false})}
          sx={{ position: 'absolute', right: 8, top: 2 }}
        >
          <CloseIcon sx={{fill:"#2D2A24"}}/>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center', paddingY: 2 ,backgroundColor:"#F6F6F3"}}>
    
        <Box sx={{backgroundColor:"white",borderRadius: "8px", textAlign:"left"}}>
                        <Grid sx={{backgroundColor:'',margin:0.5,padding:1,borderRadius: "8px", }} >
                        <Typography sx={[styles.selectedTitle,{fontWeight:"700",fontSize:"16px",font:"Inter",color:"#565047"}]}>Referral Information</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Organization</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedReferral.attributes.organization_name  }</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Contact Person</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedReferral.attributes.organization_info.attributes.contact_person}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Contact Phone</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedReferral.attributes.organization_info.attributes?.organization_phone}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Address</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedReferral.attributes.organization_info.attributes.address}</Typography>
                      
                       </Grid>       
                      </Box>
                      <Divider sx={{ my: 2,height:"2px" }} />
                      <Button sx={[{paddingLeft:"28%",paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none', backgroundColor:"#F5C502", color: "black", font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]} 
                    onClick={()=>this.setState({openEditModal:true,openUpdateModal:false,selectedRefOrg:{
                      id: "",
                      organization_name: ""
                    },refOrgDetails:{
                      organization: {
                        id: "",
                        attributes: {
                          organization_name: "",
                          contact_person: "",
                          organization_phone: "",
                          address: ""
                        }
                      },
                      is_bed_availble: false
                    }})}
                      data-test-id={"openeditButton"}>Update Referral</Button>
                      <Button sx={[{paddingLeft:"28%",paddingRight:"28%",marginTop:1.5,marginBottom:0.5,
                      textTransform: 'none',  color: "black", font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px",border:"2px red solid",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}
                    onClick={()=>this.setState({openCancelModal:true,openUpdateModal:false})}
                      data-test-id={"btnEditButton"}>Cancel Referral</Button>
      </DialogContent>
    </Dialog>
  )
  showDate(){
    return(
      <Typography sx={{textAlign:"left",fontSize:"12px"}}>   {this.formatDate(this.state.selectedReferral.attributes.updated_at?this.state.selectedReferral.attributes.updated_at:this.state.selectedReferral.attributes.created_at)}</Typography>
    )
  }
  renderEditModal = () => (
    <Dialog
      open={this.state.openEditModal}
      onClose={()=>this.setState({openEditModal:false})}
      maxWidth="xs"
      fullWidth
      data-test-id={"editcloseButton"}
    >
      <DialogTitle sx={{backgroundColor:"#F6F6F3"}}>
        <Typography sx={webStyles.modalHeading}> Edit Referral </Typography>
        <Typography sx={{textAlign:"left",fontSize:"12px"}}>{this.state.selectedReferral.attributes.sub_service_name}
        </Typography>
        <Grid container flexDirection={"row"}>
            <Grid item>
            <Typography sx={{fontWeight:"bold",fontSize:"12px",color:"#2D2A24",marginRight:1}}> {this.state.selectedReferral?.attributes?.status.charAt(0).toUpperCase() + this.state.selectedReferral?.attributes?.status.slice(1)}  </Typography>
            </Grid>
            <Grid item>
              {this.showDate()}
           
            </Grid>
        </Grid>
        <IconButton data-test-id={"editclickButton1"} onClick={()=>this.setState({openEditModal:false})} sx={{ position: 'absolute', right: 8, top: 2 }}
        > <CloseIcon sx={{fill:"#2D2A24"}}/></IconButton></DialogTitle>
{this.state.updateRef ? 
<DialogContent sx={{ textAlign: 'center', paddingY: 4 ,backgroundColor:"#F6F6F3"}}>
    <Box sx={{backgroundColor:"white",borderRadius: "8px", textAlign:"left"}}>
    <Grid sx={{backgroundColor:'',margin:0.5,borderRadius: "8px", }} >

                            <Typography sx={{fontWeight:"700",fontSize:"16px",font:"Inter",color:"#2D2A24",marginLeft:1}}>
                              Referral Information
                              </Typography>
                        <Typography sx={[styles.selectedTitle,{}]}>Organization
                            </Typography>
                        <Typography 
                        sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",
                            font:"Inter"}]}>{this.state.refOrgDetails.organization.attributes.organization_name  }</Typography>
                        <Typography 
                        sx={[styles.selectedTitle,

                            {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                            Contact Person </Typography>
                        <Typography 
                        sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.refOrgDetails.organization.attributes.contact_person}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                            Contact Phone
                            </Typography>
                        <Typography 
                        sx={[styles.selectedValue,{}]}>{this.state.refOrgDetails.organization.attributes.organization_phone}</Typography>
                        <Typography 
                        sx={[styles.selectedTitle,
                            {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Address</Typography>

                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>

                        {this.state.refOrgDetails.organization.attributes.address}
                        </Typography>
                       </Grid> 
                        
                      </Box>
                      <Divider sx={{ my: 2,height:"2px" }} />
                       <Box sx={{
                        backgroundColor: '#FEF3C7',  color: '#D97706',
                        padding: '10px 10px',
                        borderRadius: '4px',                        display: 'flex',
                        alignItems: 'center',                        borderLeft: '4px solid #D97706',
                        marginBottom: 3
                      }}>
                        <Typography variant="body2" textAlign={"left"} color="#D97706" sx={{ fontWeight: "700",margin:1 }}>
                        You are about to update the Referral information. Are you sure? 
                        </Typography>
                      </Box>
                       <Grid sx={{alignItems:"center",textAlign:"center"}} container>
                        <Grid item xs={6}> 
                            <Button sx={[{paddingLeft:"28%",paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none',  color: "black", 
                      font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px"
                      ,border:"2px red solid",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}
                    onClick={()=>this.setState({openEditModal:false,updateRef:false})}
                      data-test-id={"editcancelButton1"}>No </Button></Grid>
                        <Grid item xs={6}>
                          <Button sx={[{paddingLeft:"28%", paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none', backgroundColor:this.state.refOrgDetails.is_bed_availble?"#F5C502":"#E2E8F0", 
                      color: "black", font: "Inter", fontSize: "16px", fontWeight: "700",height: "44px",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}

                    disabled={!this.state.refOrgDetails.is_bed_availble}
                    onClick={()=>this.updateReferral()}
                      data-test-id={"btnEditmodalButton"}>Yes 
                      </Button>
                      
                        </Grid>
                      </Grid> 
</DialogContent>:
      <DialogContent sx={{ textAlign: 'center', paddingY: 4 ,backgroundColor:"#F6F6F3"}}>
     
        <Box sx={{backgroundColor:"white",borderRadius: "8px", textAlign:"left"}}>
        <Grid sx={{backgroundColor:'',margin:0.5,padding:1,borderRadius: "8px", }} >
        <Typography sx={[styles.selectedTitle,{fontWeight:"700",fontSize:"16px",font:"Inter",color:"#565047"}]}>Referral Information</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Organization</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedReferral.attributes.organization_name  }</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Contact Person</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedReferral.attributes.organization_info.attributes.contact_person}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Contact Phone</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedReferral.attributes.organization_info.attributes.organization_phone}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Address</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedReferral.attributes.organization_info.attributes.address}</Typography>
                      
                       </Grid>    
                      </Box>
                      <Divider sx={{ my: 2,height:"2px" }} />
                      <Typography variant="h6" textAlign={"left"} style={styles.areaText}>Organization</Typography>
        <FormControl fullWidth variant="outlined"
          sx={styles.formControl}>
          <Select
            value={this.state.selectedRefOrg.organization_name?this.state.selectedRefOrg.organization_name:""}
            displayEmpty
          data-test-id="SelectItem"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            renderValue={() => this.state.selectedRefOrg.organization_name?this.state.selectedRefOrg.organization_name:'Select an Organization'
            }
            sx={styles.selectMenu}
          >
            {this.state.referralorg?.map((item, index) => (
                <MenuItem
                key={item.organization_name}
                >
           <Typography  data-test-id="SelectItemClick" onClick={(text:any)=>this.handleSelectChange(item)} sx={{padding:1}} textAlign={"left"}>{item.organization_name}</Typography>
          </MenuItem>
            ))}
          </Select>
        </FormControl>
        {this.state.refOrgDetails.organization.attributes.organization_name?
       <span>
                        <Grid sx={{backgroundColor:'',margin:0.5,padding:1,borderRadius: "8px",textAlign:"left" }} >
                        <Typography sx={[styles.selectedTitle,{fontWeight:"700",fontSize:"16px",font:"Inter",color:"#565047"}]}>Information</Typography>
                        <Typography sx={[styles.selectedTitle,
                            {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                            Contact Person
                            </Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",
                            fontSize:"16px",font:"Inter"}]}>{this.state.refOrgDetails.organization.attributes.contact_person}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                            Contact Phone</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.refOrgDetails.organization.attributes.organization_phone}</Typography>
                        <Typography sx={[styles.selectedTitle,
                            {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Address</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>
                            {this.state.refOrgDetails.organization.attributes.address}</Typography>
                       </Grid> 
                       <Divider sx={{ my: 2,height:"2px" }} />
                       <Box sx={{backgroundColor:"white",margin:0.5,padding:1,borderRadius: "8px", textAlign:"left"}}>
                       <Typography sx={[styles.selectedTitle,
                            {fontWeight:"700",fontSize:"16px",font:"Inter"}]}>
                          Bed Information
                            </Typography>
                            <Typography sx={[styles.selectedTitle,
                            {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                         Bed Available
                            </Typography>
                            {this.state.refOrgDetails.is_bed_availble?
                             <Typography sx={[styles.selectedTitle,
                                {marginLeft:3,fontWeight:"700",fontSize:"16px",font:"Inter",color:"#059669"}]}>
                             There are beds available
                                </Typography>:
                                 <Typography sx={[styles.selectedTitle,
                                    {marginLeft:3,fontWeight:"700",fontSize:"16px",font:"Inter",color:"#DC2626"}]}>
                                 No beds available
                                    </Typography>}
                      </Box></span>:null}
                      <Grid container>
                        <Grid item xs={6}> 
                            <Button sx={[{paddingLeft:"28%",paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none',  color: "black", 
                      font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px"
                      ,border:"2px red solid",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}
                    onClick={()=>this.setState({openEditModal:false})}
                      data-test-id={"editupdateButton"}>Cancel </Button></Grid>
                        <Grid item xs={6}>
                          <Button sx={[{paddingLeft:"28%", paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none', backgroundColor:this.state.refOrgDetails.is_bed_availble?"#F5C502":"#E2E8F0", color: "black", font: "Inter"
                      , fontSize: "16px", fontWeight: "700",height: "44px",
                      '&:hover': {
                        backgroundColor: '#E2E8F0', },}]}

                        
                    disabled={!this.state.refOrgDetails.is_bed_availble}
                    onClick={()=>this.setState({updateRef:true})}
                      data-test-id={"btnupdateButton"}>Update 
                      </Button>
                      
                        </Grid>
                      </Grid>     
      </DialogContent>}
    </Dialog>
  )
  renderCancelModal = () => (
    <Dialog
      open={this.state.openCancelModal}
      onClose={()=>this.setState({openCancelModal:false})}
      maxWidth="xs"
      fullWidth
      data-test-id={"cancelcloseButton"}
    >
      <DialogTitle sx={{backgroundColor:"#F6F6F3"}}>
        <Typography sx={webStyles.modalHeading}>
        Cancel Referral 
        </Typography>
        <Typography sx={{textAlign:"left",fontSize:"12px"}}>
       {this.state.selectedReferral.attributes.sub_service_name}
        </Typography>
        <Grid container flexDirection={"row"}>
            <Grid item>
            <Typography sx={{fontWeight:"bold",fontSize:"12px",color:"#2D2A24",marginRight:1}}> {this.state.selectedReferral?.attributes?.status.charAt(0).toUpperCase() + this.state.selectedReferral?.attributes?.status.slice(1)}  </Typography>
            </Grid>
            <Grid item>
            <Typography sx={{textAlign:"left",fontSize:"12px"}}>   {this.formatDate(this.state.selectedReferral.attributes.updated_at?this.state.selectedReferral.attributes.updated_at:this.state.selectedReferral.attributes.created_at)}</Typography>
            </Grid>
        </Grid>
        
        <IconButton data-test-id={"editclickButton"}
          onClick={()=>this.setState({openCancelModal:false})}
          sx={{ position: 'absolute', right: 8, top: 2 }}
        >
          <CloseIcon sx={{fill:"#2D2A24"}}/>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center',backgroundColor:"#F6F6F3"}}>
     
        <Box sx={{backgroundColor:"white",margin:0.5,borderRadius: "8px",paddingY:2, textAlign:"left"}}>
                        <Grid sx={{backgroundColor:'',margin:0.5,borderRadius: "8px", }} >
                            <Typography sx={{fontWeight:"700",fontSize:"16px",font:"Inter",color:"#2D2A24",marginLeft:1}}>Referral Information</Typography>
                        <Typography sx={[styles.selectedTitle,{}]}>
                            Organization
                            </Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",
                            font:"Inter"}]}>{this.state.selectedReferral.attributes.organization_name  }</Typography>
                        <Typography sx={[styles.selectedTitle,
                            {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                            Contact Person
                            </Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",
                            fontSize:"16px",font:"Inter"}]}>{this.state.selectedReferral.attributes.organization_info.attributes.contact_person}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                            Contact Phone</Typography>
                        <Typography sx={[styles.selectedValue,{}]}>{this.state.selectedReferral.attributes.organization_info.attributes.organization_phone}</Typography>
                        <Typography sx={[styles.selectedTitle,
                            {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Address</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>
                        {this.state.selectedReferral.attributes.organization_info.attributes.address}</Typography>
                       </Grid> 
                      </Box>
                      <Divider sx={{ my: 2,height:"2px" }} />
                      <Typography variant="body2" textAlign={"left"} sx={styles.lableText}>Cancellation Reason</Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          sx={styles.subBoxinputField}
          value={this.state.reason}
          onChange={(text)=>this.setState({reason:text.target.value})}
        />
         <Box sx={{
                        backgroundColor: '#FEF3C7',  color: '#D97706',
                        padding: '10px 10px',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        borderLeft: '4px solid #D97706',
                        marginBottom: 3
                      }}>
                        <Typography variant="body2" textAlign={"left"} color="#D97706" sx={{ fontWeight: "700",margin:1 }}>
                        You are about to cancel the Referral. <br style={{margin:20}}/>
                        Are you sure you want to cancel the referral?
                        </Typography>
                      </Box>
                      <Grid container>
                        <Grid item xs={6}> 
                            <Button sx={[{paddingLeft:"28%",paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none',  color: "black", 
                      font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px"
                      ,border:"2px red solid",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}
                    onClick={()=>this.setState({openCancelModal:false})}
                      data-test-id={"editcancelButton"}>
                        No </Button></Grid>
                        <Grid item xs={6}>
                        <Button onClick={()=>this.cancelReferral()} sx={[{paddingLeft:"28%",
                        paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none', backgroundColor:"#F5C502", 
                      color: "black", font: "Inter"
                      , fontSize: "16px", fontWeight: "700",
                      height: "44px",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}
                      data-test-id={"btncancelButton"}>Yes 
                      </Button>
                      
                        </Grid>
                      </Grid>     
      </DialogContent>
    </Dialog>
  )

  formatDate = (dateString:string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
   getServiceLabel = (type: {id:string,attributes:{organization_id:number,organization_name:string,created_at:string,updated_at:string,sub_service_name: string;status:string}}) => {
      return(
        <span>
     
           {  this.state.selectedFilter==="Organization"?
            <Grid container sx={{backgroundColor: 'white',borderRadius:"8px",padding:2,marginBottom:1}}>
         <Grid item xs={10}>
         <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
             <Grid item xs={12}><Typography fontWeight={700} sx={{ fontSize: "14px",  font:"Inter",fontWeight:700}}>{type?.attributes?.organization_name}</Typography></Grid>
             <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px", font:"Inter",fontWeight:400}}>{type?.attributes?.sub_service_name}</Typography></Grid>
             <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px", font:"Inter",fontWeight:400}}> {this.formatDate(type?.attributes?.updated_at?type?.attributes?.updated_at:type?.attributes?.created_at)}</Typography></Grid>
           </Grid>
         </Grid>
         <Grid data-test-id="openModalButton" item xs={2}>
           <div style={{height:"48px",width:"48px", backgroundColor:"#FCE38B",borderRadius:"8px",textAlign:"center",verticalAlign:"middle"}}> 
              <TrendingFlatIcon data-test-id="getRefId" onClick={()=>this.selectedRefDetails(type.id)} sx={{fontSize:"24px",fill:"black",verticalAlign:"middle",marginTop:"25%"}}/></div>
      
         </Grid>
          </Grid>
         :<span>
            {this.state.selectedFilter==="All" ?<Grid container sx={{backgroundColor: 'white',borderRadius:"8px",padding:2,marginBottom:1}}>
         <Grid item xs={10}>
         <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
             <Grid item xs={12}><Typography fontWeight={700} sx={{ fontSize: "14px",  font:"Inter",fontWeight:700}}>{type?.attributes?.organization_name}</Typography></Grid>
             <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px", font:"Inter",fontWeight:400}}>{type?.attributes?.sub_service_name}</Typography></Grid>
             <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px", font:"Inter",fontWeight:400}}>{this.formatDate(type?.attributes?.updated_at?type?.attributes?.updated_at:type?.attributes?.created_at)}</Typography></Grid>
           </Grid>
         </Grid>
         <Grid  item xs={2}>
           <div style={{height:"48px",width:"48px", backgroundColor:"#FCE38B",borderRadius:"8px",textAlign:"center",verticalAlign:"middle"}}> 
              <TrendingFlatIcon data-test-id="openModalButton1" onClick={()=>this.selectedRefDetails(type.id)} sx={{fontSize:"24px",fill:"black",verticalAlign:"middle",marginTop:"25%"}}/></div>
      
         </Grid>
          </Grid>:<Grid container sx={{backgroundColor: 'white',borderRadius:"8px",padding:2,marginBottom:1}}>
         <Grid item xs={10}>
         <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
             <Grid item xs={12}><Typography fontWeight={700} sx={{ fontSize: "14px",  font:"Inter",fontWeight:700}}>{type?.attributes?.organization_name}</Typography></Grid>
             <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px", font:"Inter",fontWeight:400}}>{type?.attributes?.sub_service_name}</Typography></Grid>
             <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px", font:"Inter",fontWeight:400}}>{this.formatDate(type?.attributes?.updated_at?type?.attributes?.updated_at:type?.attributes?.created_at)}</Typography></Grid>
           </Grid>
         </Grid>
         <Grid  item xs={2}>
           <div style={{height:"48px",width:"48px", backgroundColor:"#FCE38B",borderRadius:"8px",textAlign:"center",verticalAlign:"middle"}}> 
              <TrendingFlatIcon data-test-id="openModalButton2" onClick={()=>this.selectedRefDetails(type.id)} sx={{fontSize:"24px",fill:"black",verticalAlign:"middle",marginTop:"25%"}}/></div>
      
         </Grid>
          </Grid>}
          </span>
    }
     </span>
    
      )          
  };
 
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { isToggled, selectedFilter, selectedService, selectedDate, isServicesOpen } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start

          <NavigationMenu navigation={this.props.navigation} id={""}>
              <TextContainer>
        <Button data-test-id="btnGoback" onClick={()=>this.goback("LandingPage")} sx={webStyles.goBackButton}>
           <KeyboardArrowLeftIcon sx={{color:"black"}} />
            <Typography style={webStyles.goBackText}>Go Back</Typography>
        </Button>
                <Typography style={webStyles.comunityType}>Update Referrals</Typography>  
                <Box sx={styles.mainBox}>
                            <Box sx={styles.filterBox}>
                                <Typography sx={styles.filterText}>Filter:</Typography>
                                <div>
                                    <Button onClick={this.handleToggle} data-test-id="toggle_button" sx={styles.filterButton}>
                                        <img src={filterIcon.default} alt="Filter" />
                                    </Button>
                                </div>
                            </Box>
                            {isToggled &&
                                <>
                                    <div style={styles.filterContainer}>
                                        <FormControl component="fieldset" style={{ width: "100%", display: "block" }}>
                                            <RadioGroup
                                                test-id="radioButton"
                                                value={selectedFilter}
                                                onChange={this.handleFilterChange}>
                                                <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                                                    {["All", "Organization", "Date"].map((label, index) => (
                                                        <li key={label} style={{ borderBottom: index == 2 ? "none" : "1px solid #ddd", padding: "5px" }}>
                                                            <FormControlLabel
                                                                key={label}
                                                                value={label}
                                                                label={label}
                                                                control={<BpRadio />}
                                                                style={{ marginLeft: "1px" }}
                                                            />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </RadioGroup>
                                        </FormControl>
                                    </div>

                                    {selectedFilter == "Date" && (
                                        <Box sx={{ marginTop: "10px", width: "100%", gap: "10px" }}>
                                            <TextField
                                                data-testid="selected-date-input"
                                                variant="outlined"
                                                value={selectedDate && selectedDate.toLocaleDateString()}
                                                sx={styles.inputRoot}
                                            />
                                            <DatePickerComp >
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={this.handleDateChange}
                                                    dateFormat="yyyy-MM-dd"
                                                    inline
                                                     data-testid="date-input"
                                                />
                                            </DatePickerComp>
                                        </Box>
                                    )}

                                    {selectedFilter == "Organization" &&
                                        <Box sx={{ marginTop: "10px" }}>
                                            <FormControl fullWidth>
                                                <Select
                                                    labelId="dropdown-label"
                                                    data-test-id="selected-service-input"
                                                    value={selectedService.attributes?.organization_name}
                                                    onClick={()=>this.handleServiceToggle()}
                                                    open={false}
                                                    displayEmpty
                                                    renderValue={this.display}
                                                    sx={styles.serviceSelect}
                                                >
                                                </Select>
                                            </FormControl>
                                            {isServicesOpen && (
                                                <Paper sx={{ marginTop: "10px", borderRadius: "8px", border: "1px solid #E2E8F0", boxShadow: 2 }}>
                                                    {this.state.allOrg.map((service:any) => (
                                                        <MenuItem
                                                            key={service.attributes.organization_name}
                                                            data-test-id="menuItem"
                                                            value={selectedService.attributes?.organization_name}
                                                            onClick={() => this.handleServiceChange(service)}
                                                            sx={styles.menuItem}>
                                                            <Typography sx={styles.label}>{service.attributes?.organization_name}</Typography>
                                                        </MenuItem>
                                                    ))}
                                                </Paper>
                                            )}
                                        </Box>
                                    }
                                </>
                            }
                        </Box>
                <View style={{backgroundColor:"#D9D9D9",height:"2px",marginVertical:15}} />
                {this.state.orgnisation?.map((formData) => (
               
               <Box>
                    {this.getServiceLabel(formData)}
               </Box>
                                      
                ))}
              
            
        {this.renderModal()}
        {this.renderEditModal()}
        {this.renderCancelModal()}
        {this.renderResultModal()}
                </TextContainer>
          </NavigationMenu>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const TextContainer = styled(Box)({
    height:"auto",
    backgroundColor: '#F6F6F3',
    padding:"16px",
  });
const webStyles = {
    modalHeading: {
        width:"80%",
        fontWeight: 700,
        color:"#2D2A24",
        fontSize:"24px", 
      },
    headerLogo: {
      width: "122.32px",
      height: "28px",
      backgroundColor: "#F5C502",
      borderRadius: 13,
      marginRight: 10
    },
    goBackButton: {
      display: 'inline-flex',
      alignItems: 'center',
      textTransform: 'none',
    },
    goBackText: {
      fontWeight: 400,
      fontSize: '14px',
      color: '#1D4ED8',
      lineHeight: '22px',
    },
    comunityType: {
      color:"#2D2A24",
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing:"-0.05%",
      marginTop:"16px"
    },
  };
  const DatePickerComp = styled(Box)(({ theme }) => ({
    ".react-datepicker": {
        width: "100%",
        maxWidth: "100%",
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
    },
    ".react-datepicker__month-container": {
        width: "100%",
        maxWidth: "100%",
    },
    '.react-datepicker-wrapper': {
        width: "100%",
    },
    ".react-datepicker__input-container": {
        width: "100%",
    },
    ".react-datepicker__day-names": {
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        width: "100%",
        textAlign: "center",
        margin: "0.4rem",
    },
    ".react-datepicker__week": {
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        width: "100%",
        textAlign: "center",
        margin: "0",
        padding: "0",
    },
    ".react-datepicker__input-container input": {
        width: "100%",
        padding: "15px",
        borderRadius: "5px",
        outline: "none",
        borderColor: "#CBD5E1",
        borderWidth: "1px",
        borderStyle: "solid",
    },
    ".react-datepicker__header": {
        textAlign: "center",
        backgroundColor: "#fff",
        borderBottom: "none",
        padding: "17px 0 0 0",
        position: "relative",
    },
    ".react-datepicker__day": {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        height: "2.5em",
        width: "100%",
        maxWidth: "100%",
        margin: "0",
    },
    ".react-datepicker__day--selected": {
        backgroundColor: "#FFFDE7",
        border: "1px solid #FFEC42",
        borderRadius: "20%",
        color: "#333",
    },
}))

const styles = {
    selectMenu: {
        height: "44px",
        color: "#94A3B8",
        fontSize: "16px",
        fontWeight: 400,
        textAlign: "left",
      },
      areaText: {
        color:"#2D2A24",
        fontSize: '16px',
        fontWeight: 400,
        // margin:"32px 0 8px 0"
        
      },
      formControl: {
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        border: "1px solid #CBD5E1",
        height:"44px",
      },
    lableText: {
        fontWeight: 700,
        fontSize:"16px",
        color:"#2D2A24",
        marginBottom:"6px",
       
      },
      subBoxinputField: {
        backgroundColor:"#FFFFFF",
        marginBottom:"12px",
        fontSize: "16px",
        width:"100%",
        height:"130px",
        color: "#94A3B8",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        fontWeight: 400,
        '& .MuiOutlinedInput-root': {
          borderRadius: "8px",
          '& fieldset': {
            borderColor: "transparent",
          },
          '&.Mui-focused fieldset': {
            borderColor: "transparent",
          },
          '&:hover fieldset': {
            borderColor: "transparent",
          },
        }
      },
    selectedTitle:{marginLeft:1,margin:1,color:"#2D2A24",fontWeight:"400",fontSize:"16px",font:"Inter"},
    selectedValue:{marginLeft:2,marginBottom:1,color:"#565047",fontWeight:"700",fontSize:"16px",font:"Inter"},
    container: {
        flex: 1,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "20px",
        alignItems: "center",
        backgroundColor: "#fff",
    },
    goback: {
        height: "22px",
        width: "80px",
        display: "flex",
        gap: "5px",
        alignItems: "center",
        marginBottom: "10px",
    },
    arrowIcon: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#2D2A24"
    },
    goBackText: {
        color: "#1D4ED8",
        fontSize: "14px",
        fontWeight: 400,
    },
    heading: {
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 700,
        fontFamily: "Inter",
        marginBottom: "15px"
    },
    mainBox: {
        background: "#FFFFFF",
        borderRadius: "10px",
        padding: "10px 20px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
    },
    filterBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    filterText: {
        flex: 1,
        fontWeight: "700",
        fontSize: "16px",
        font: "Inter"
    },
    filterButton: {
        border: "1px solid #CBD5E1",
        height: "45px",
        minWidth: '45px',
        borderRadius: "8px",
        boxShadow: '0px 2px 8px 0px #00000014',
    },
    filterContainer: {
        border: "1px solid #CBD5E1",
        borderRadius: 8,
        marginTop: "5px"
    },
    services: {
        borderRadius: "8px",
        backgroundColor: "#fff",
    },
    serviceSelect: {
      fontSize: "16px",
        fontWeight: "400",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        "&.Mui-focused": {
            border: "1px solid #CBD5E1",
        },
        "&:hover": {
            border: "1px solid #CBD5E1",
        }
    },
    menuItem: {
        padding: "10px",
        borderBottom: "1px solid #E2E8F0",
        "&.Mui-selected": {
            backgroundColor: "#fffde7 !important",
        },
        "&:last-child": { borderBottom: "none" },
        "&:focus": {
            backgroundColor: "#fffde7",
            outline: "none",
        },
        "&:active": {
            backgroundColor: "#fffde7",
        },
    },
    label: {
        color: "#2D2A24",
        fontSize: "12px",
        fontWeight: 700,
    },
    select: {
        backgroundColor: "#fff",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        padding: "8px",
        "& .MuiSelect-icon": {
            fontSize: "20px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "&:hover": {
            backgroundColor: "#f9f9f9",
        },
    },
    selectedItem: {
        backgroundColor: "#fffde7 !important",
    },
    inputRoot: {
        width: "100%",
        borderRadius: "8px",
        marginBottom: "10px",
        '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#CBD5E1',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFF787',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#FFF787',
            },
        },
    },
    dataBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        borderRadius: "5px",
        padding: "5px",
        marginBottom: "12px",
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    },
    tableHeading: {
        fontWeight: 700,
        fontSize: "12px",
        fontFamily: "Inter"
    },
    IconStyles: {
        backgroundColor: "#FCE38B",
        borderRadius: "8px",
        padding: "8px",
        "&:hover": {
            backgroundColor: "#FCE38B",
        },
        '&:focus': {
            backgroundColor: '#FCE38B', 
        },
    }

}
const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 24,
  height: 24,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
  },
  'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
      ...theme.applyStyles('dark', {
          backgroundColor: '#30404d',
      }),
  },
  'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
      ...theme.applyStyles('dark', {
          background: 'rgba(57,75,89,.5)',
      }),
  },
  ...theme.applyStyles('dark', {
      boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
      backgroundColor: '#394b59',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
  }),
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#CF9500',
  backgroundImage: 'linear-gradient(180deg, hsla(45, 100%, 50%, 0.1), hsla(45, 100%, 60%, 0))',
  '&::before': {
      display: 'block',
      width: 24,
      height: 24,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
  },
  'input:hover ~ &': {
      backgroundColor: '#CF9500',
  },
});
// Customizable Area End
