import React from "react";

// Customizable Area Start

import { View, StyleSheet } from "react-native";
import {
  FormControl,
  Grid,
  Input, FormHelperText,
  Button, InputAdornment, IconButton,
  Typography, Box,
} from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu";
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <NavigationMenu  navigation={this.props.navigation}  id={""} >
      <Grid container sx={{ flexDirection: { md: "row-reverse" }, backgroundColor: "#f3f3f1" }}>
        <Grid item xs={12} md={8}>
          <View style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
            <Grid container direction="column"  >
           
              <Grid item xs={8} style={{ padding: 5 }}>

                <View style={{
                  display: "flex",
                  paddingTop: "20px", padding: 10
                }}>
                  <Typography sx={{
                    flex: 1,
                    padding: "10px", fontWeight: "700",fontSize:"24px",font:"Inter",height:"32px"
                  }}>
                    Login
                  </Typography>
                  <View style={{
                    flex: 1,
                    padding: "10px"
                  }}>

                    {this.state.loginFailMsg.length > 0 &&
                      <Box sx={{
                        backgroundColor: '#ffe5e5',
                        color: '#d32f2f',
                        padding: '10px 16px',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        borderLeft: '4px solid #d32f2f',
                        marginBottom: 3
                      }}>
                        <Typography variant="body2" color="error" sx={{ fontWeight: "700" }}>
                          {this.state.loginFailMsg}
                        </Typography>
                      </Box>}
                    <Typography sx={{fontSize:"16px",fontWeight:700,marginBottom:1}} component="h1" style={styles.textFont}>
                      Email
                    </Typography>
                    <FormControl error={this.state.error} fullWidth required>
                      <Input
                        type="email"
                        disableUnderline
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid ', borderColor: this.state.error ? "red" : "#ccc"
                        }}
                        data-test-id="txtInputEmail"
                        placeholder={"Email"}
                        value={this.state.email}
                        onChange={(e) => this.setEmail(e.target.value)}
                        onBlur={() => this.handleBlur()}
                      />
                      <FormHelperText sx={{ marginLeft: -0.1 }}>{this.state.error ? this.state.emailMessage : ''}</FormHelperText>
                    </FormControl>

                  </View>
                  <View style={{
                    flex: 1,
                    padding: "10px"
                  }}>
                    <Typography sx={{fontSize:"16px",fontWeight:700,marginBottom:1}}  component="h1" style={styles.textFont}>
                      Password
                    </Typography>
                    <FormControl error={this.state.errorPass} fullWidth>
                      <Input
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid', borderColor: this.state.errorPass ? "red" : "#ccc"
                        }}
                        disableUnderline
                        data-test-id="txtInputPassword"
                        type={this.state.enablePasswordField ? 'password' : 'text'}
                        placeholder={"Password"}
                        onBlur={() => this.handlePassblur()}
                        value={this.state.password}
                        onChange={this.setPassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              data-test-id="togglePasswordButton"
                              onClick={this.handleClickShowPassword}
                              edge="end"
                            >
                              {this.state.enablePasswordField ? <VisibilityOffOutlinedIcon style={{ fill: "grey" }} /> : <VisibilityOutlinedIcon style={{ fill: "grey" }} />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText sx={{ marginLeft: -0.2 }}>{this.state.errorPass ? this.state.passMessage : ''}</FormHelperText>
                    </FormControl>
                  </View>
                  <View style={{ display: "flex", alignItems: "flex-start" }}>
                    <Button disableTouchRipple disableRipple
                      
                      sx={{
                        flex: 1, color: "#1D4ED8", marginBottom: 2,
                        textTransform: 'none',fontSize:"14px",fontWeight:400
                      }}
                      
                    ><Typography data-test-id={"btnForgotPassword"} sx={{marginLeft:0.5}} onClick={() => this.goToForgotPassword()}>Forgot your password</Typography></Button>

                  </View>
                  <View
                    style={{
                      flex: 1,
                      padding: "10px"
                    }}
                  >
                    <Button sx={[{
                     textTransform: 'none', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px", fontWeight: "700",borderRadius:"8px",lineHeight:"24px",
                      height: "44px",
                      '&:hover': {
                        backgroundColor: '#FFC300',
                      },
                    }]}
                      data-test-id={"btnEmailLogIn"}
                      onClick={() => this.login()}
                    >Login</Button>
                  </View>
                  <View style={{
                    flex: 1, justifyContent: "center", alignItems: "center", margin: "20px"
                  }}>
                    <Typography component="h1" style={{ fontSize: "14px" }} >
                      <span>Do you have a question? </span>
                      <span  data-test-id={"btnConatctUs"} onClick={()=>this.goToContactusPage()} style={{ color: "#1D4ED8" }}> Contact Us</span>
                    </Typography>
                  </View>
                </View>
              </Grid>
            </Grid>
          </View>
        </Grid>
      
      </Grid>
      </NavigationMenu>
      // Customizable Area End
    );
  }

}
// Customizable Area Start
const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica",
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px"
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent"
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  footerText: {
    textAlign: "center",
    fontWeight: "700",
    margin: 5
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  }
});
// Customizable Area End
