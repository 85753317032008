import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  InputAdornment,
  Input,
  Select,
  MenuItem,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { GoogleMap,  Marker,  OverlayView } from '@react-google-maps/api';
import { search } from "../../reservations/src/assets";
import { View } from "react-native";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
const containerStyle = {
  width: '100%',
  height: '700px',
  borderRadius:"8px"
};
const defaultCenter = {
  lat: 28.6336,
  lng: 77.2050,
};
// Customizable Area End

import LocationController, {
    Props,
    configJSON
  } from "./LocationController";

export default class Location extends LocationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  customCircleIcon = {
    path: "M 0,0 a 10,10 0 1,0 20,0 a 10,10 0 1,0 -20,0",
  fillColor: "#F5C502",
  fillOpacity: 1,
  strokeColor: "#F5C502",
  strokeOpacity: 0.1,
  strokeWeight: 40,
  scale: 1,
  };
  // Customizable Area End
  render() {
  // Customizable Area Start
  // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      
    <Box sx={webStyle.landingPageView}>
   
      <Box>
      <View style={{ flexDirection: "row", justifyContent: "space-between", shadowColor: '#CBD5E1',
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 10,
    elevation: 8,
    borderWidth: 1,
    borderColor: '#CBD5E1',height:"56px",
    borderRadius: 10, alignContent: "center", alignItems: "center", padding: 2, backgroundColor: "white",marginBottom:10}}>
                <Input
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", 
                    fontWeight: "400",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0%",
                    verticalAlign: "middle",
                    
                  }}
                  disableUnderline
                  data-test-id="txtInputSearch"
                  type={'text'}
                  placeholder={"Search"}
                  value={this.state.searchQuery}
                  onChange={(text)=>this.handle(text)}
                  startAdornment={
                    <InputAdornment sx={{ marginBottom: 0.5 }} position="end">
                      <img style={{ height: "24px",width:"24px", marginRight: 8 }} src={search.default} />
                    </InputAdornment>
                  }
                />
                
              </View>
             
              {this.state.searchResults && this.state.searchResults?.length>0 && (
          <List sx={{zIndex:7,   backgroundColor: "#fff",padding:0, borderRadius: "5px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)" }}>
            {this.state.searchResults?.map((place, index) => (
              <ListItem sx={{padding:0}} key={index} >
                <ListItemButton data-test-id="handleSelectPlaceBtn" onClick={()=>this.handleSelectPlace(place)}>
                <ListItemText  primary={place.formatted_address} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
              </Box>
             
              <Box sx={{position:"absolute",top:66,left:0,zIndex:2,backgroundColor:"#FFFFFFB2",padding:1,
// padding: "8px",
gap: "4px",
borderBottomRightRadius: "8px"
}}>
                <Typography style={{fontWeight:"700",fontSize:"16px"}}>{configJSON.pin}</Typography>
                <Select
              data-test-id="serviceTestId" IconComponent={ExpandMoreIcon}
            sx={{borderRadius:"8px",backgroundColor:"#FFFFFF",paddingX:0.5,boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",height:"44px", "& .MuiSelect-icon": {
          right: "50%", 
          transform: "translateX(50%)", 
        },
             }}
              MenuProps={{
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
                transformOrigin: { vertical: "top", horizontal: "left" },
                PaperProps: {
                  sx: {
                    // left: -70,
                    marginTop:1,
                    width:"85%",
                    left:55,
                    backgroundColor: "white", 
                    boxShadow: "none" ,borderRadius:"8px"
                  },
                },
              }}
            >
              <span >
              {this.state.pins.map((item, index) => ( 
                 <MenuItem key={item.name}  data-test-id="pinTestid" sx={{ borderBottom: index === this.state.pins.length - 1 ? 'none' :"1px solid #ddd",padding:1,backgroundColor:"white",display: "flex", justifyContent: "flex-end", }} onClick={()=>this.sendDataToParent(item)}>
              <Grid style={{borderRadius:"8px",borderColor:"red"}} container flexDirection={"row"} >
                <Grid item xs={2}>
                <img src={item.icon} style={{height:"24px",width:"24px"}}></img>
                </Grid>
                <Grid item xs={10} sx={{alignContent:"center"}}>
                  <Typography sx={{ whiteSpace: "normal",  fontSize:"12px",fontWeight:"400",lineHeight:"18px",
      // wordBreak: "break-word", 
      // overflowWrap: "break-word", 
      // width: "180px", 
      // paddingRight: 8
      }}> {item.name}</Typography>
                </Grid>
             
              </Grid>
            
              </MenuItem>
            ))}
              </span>
            </Select>
              </Box>
       <GoogleMap mapContainerStyle={containerStyle} center={{
  lat: this.state.currentLat,
  lng: this.state.currentLang,
}} zoom={15} options={{mapTypeControl: false, fullscreenControl:false}}  onClick={this.handleMapClick}>
   <Marker onClick={this.handleMapClick}
   data-testid="dropLocaID"
          position={{
            lat: this.state.currentLat,
            lng: this.state.currentLang,
          }}
          icon={this.customCircleIcon}
            title={"current location"} 
        />
   <OverlayView
           position={{
                lat: this.state.lat,
                lng: this.state.lng,
              }}
           mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} 
         >
           <div
             style={{
               position: "absolute",
               transform: "translate(-50%, -50%)",
               backgroundColor: "#FFFDE7", 
              width: "60px",
            height: "60px",
               backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "50%", 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
             }}
           >
             <img
               src= {this.state.selectedPin.icon} 
               alt="Custom Marker"
               style={{
                 width: "20px",
                 height: "20px",
                 objectFit: "cover",
                
               }}
             />
           </div>
         </OverlayView>
        {this.state.locationList.map((entry,index) => (
           <OverlayView key={entry.id}
           position={{
                lat: entry.attributes.latitude,
                lng: entry.attributes.longitude,
              }}
           mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} 
         >
           <div
             style={{
               position: "absolute",
               transform: "translate(-50%, -50%)",
               backgroundColor: "#FFFDE7", 
              width: "60px",
            height: "60px",
               backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "50%", 
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
             }}
           >
             <img
               src= {entry.attributes.icon} 
               alt="Custom Marker"
               style={{
                 width: "20px",
                 height: "20px",
                 objectFit: "cover",
                
               }}
             />
           </div>
         </OverlayView>
        
        ))}
      </GoogleMap>
      <button data-testid="recenterBtn"
        onClick={()=>this.getCurrentLocation()}
        style={{
          position: "absolute",
          top: "73%",
          left: "84%",
          background: "white",
          padding: "10px",borderRadius:100,
          border: "none",
          cursor: "pointer",
        }}
      >
        <GpsFixedIcon sx={{fill:"#475569"}}/>
      </button>
      </Box>
      // Customizable Area End
    );
  }

}

// Customizable Area Start
const webStyle = {
  landingPageView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    position:"relative",
  },
  landingPageText: {
    fontSize: 42,
    letterSpacing: 2,
    fontWeight: "bold",
    color: "#323441",
    marginTop: 15
  },
  clearBtnStyle: {
    marginRight: 15,
  },
  input: {
   
  },
}
  // Customizable Area End
