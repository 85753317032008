import React from "react";
// Customizable Area Start
import {
  Box,
  Container,
  styled,
  Typography,
  Button,
  Checkbox,
  TextField,
  Paper,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  DialogActions,
  Grid
} from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import Location from "../../location/src/Location.web";
// Customizable Area End

import AdministratedServicesController, { Props } from "./AdministratedServicesController.web";
import { toInteger } from "lodash";



export default class AdministratedServices extends AdministratedServicesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }

  // Customizable Area Start
  renderFormForDrugTestingMachine = (id:number) => (
    <Box>
      <Paper sx={{ marginTop: 2 }}>
        {this.state.careActions.map((nestedCheckbox, nestedCheckboxindex) => (
         nestedCheckbox.id  === id &&
            nestedCheckbox.attributes.testing_locations.map((value, index) => (
              <Box
                data-test-id="checkBoxId"
                sx={webStyle.subCheckBoxContainer}
                key={index}
              >
                
                <Checkbox
                  data-test-id="nestedCheckBoxCallID"
                  edge="start"
                  checked={value.checked}
                  tabIndex={-1}
                  onChange={() =>
                    this.handleNestedCheckboxToggle(nestedCheckboxindex, index)
                  }
                  disableRipple
                  sx={webStyle.subCkeckBox}
                />
                <Typography style={webStyle.subMenulistItemName}>{value.name}</Typography>
              </Box>
            ))
        ))}
      </Paper>
     { this.state.selectedTestingLocationId ? <Box mt={2}>
        <Typography variant="body2" sx={webStyle.lableText}>Location (Point of Purchase)</Typography>
        <TextField
          data-test-id="locationtestId"
          fullWidth
          variant="outlined"
          size="small"
          sx={webStyle.subBoxinputField}
          value={this.state.locationOfPurchase}
          onChange={this.handleChange('locationOfPurchase')}
        />

        <Typography variant="body2" sx={webStyle.lableText}>Bag Name</Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          sx={webStyle.subBoxinputField}
          value={this.state.bagName}
          onChange={this.handleChange('bagName')}
        />

        <Typography variant="body2" sx={webStyle.lableText}>Substance</Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          sx={webStyle.subBoxinputField}
          value={this.state.substance}
          onChange={this.handleChange('substance')}
        />

        <Typography variant="body2" sx={webStyle.lableText}>Drug Test Results</Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          multiline
          rows={3}
          placeholder="Add additional information"
          sx={webStyle.subBoxinputField}
          value={this.state.drugTestResults}
          onChange={this.handleChange('drugTestResults')}
        />
      </Box>:""}
    </Box>
  );

  renderModal = () => (
    <Dialog
      open={this.state.isModalOpen}
      onClose={this.handleCloseModal}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <Typography sx={webStyle.modalHeading}>
          Performed and Facilitated Care
        </Typography>
        <IconButton
          onClick={this.handleCloseModal}
          sx={{ position: 'absolute', right: 8, top: 2,fill:"#2D2A24" }}
        >
          <CloseIcon sx={{fill:"#2D2A24"}}/>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center', paddingY: 4 }}>
      {this.state.success? <CheckCircleOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "green",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> 
          :<ErrorOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "red",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> }
        <Typography variant="h6" sx={webStyle.arrowIcon}>
        {this.state.subTitle}
        </Typography>
      </DialogContent>
    </Dialog>
  )
  displayResultImage = ()=>   {
    return(
    this.state.successImg &&
    <Box sx={{
      backgroundColor:this.state.uploadedImg ? '#ECFDF5':"#FEE2E2",  color: '#D97706',
      padding: '10px 10px',borderRadius: '4px', display: 'flex',
      alignItems: 'center',  borderLeft:this.state.uploadedImg ?  '5px solid #059669':'5px solid #DC2626',
      marginBottom: 3
    }}>
      <Typography variant="body2" textAlign={"left"} color={this.state.uploadedImg ? "#2D2A24":"#DC2626"} sx={{ fontWeight: "400",margin:1 }}>
      {this.state.subTitleImg}
      </Typography>
    </Box>
     )} 
  renderFileList = () => {
    return (
      <List>
        {this.state.selectedFiles.map((file, index) => (
          <ListItem key={index} divider sx={webStyle.uploadButtonBox}>
            <ListItemAvatar>
              <Avatar style={webStyle.pendingImageBox}>
                <ImageOutlinedIcon style={webStyle.defaultImageIcon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={file.name}
              secondary={`${(file.size / 1024).toFixed(2)} KB`}
              primaryTypographyProps={{
                noWrap: true, 
                style: {
                  overflow: "hidden", textOverflow: "ellipsis",whiteSpace: "nowrap", 
                },
              }}
            />
            <IconButton data-test-id="btnOpenImage" onClick={()=>this.viewImage(file,index)} edge="end">
              <MoreVertOutlinedIcon />
            </IconButton>
            {/* <LinearProgress
              variant="determinate"
              value={file.progress}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                height: "2px",
                backgroundColor: "#F5C502",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "white",
                },
              }}
            /> */}
             {this.state.isDropdownOpen==index+1 &&(
                      <Grid
                      style={{
                        position: "absolute",
                        top: "100%",
                        right: 0,
                        background: "#fff",
                        border: "1px solid #ccc",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        borderRadius: "4px",
                        zIndex: 1000,
                      }}
                    >
                    
                        <Grid container
                          style={{
                            padding: "8px 16px",
                            // cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            font:"Inter",
                            gap: "8px",
                          }}
                            data-test-id="btnOpenImageView"
                          onClick={()=>this.setState({openView:true,isDropdownOpen:0})}
                        >
                          <WallpaperIcon style={{ fill: "#2D2A24",marginRight:5,alignContent:"center",alignItems:"center",alignSelf:"center"}}/>
                          
                          <Grid item><Typography>View</Typography></Grid>
                        </Grid>
                        <Grid container
                          style={{
                            padding: "8px 16px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            fontFamily:"Inter",
                            gap: "8px",
                          }}
                          data-test-id="btnOpenImageRemovew"
                          onClick={()=>this.setState({removeImg:index.toString(),openView:true})}
                        >
                         <DeleteOutlineOutlinedIcon style={{ fill: "#2D2A24",marginRight:5,alignContent:"center",alignItems:"center",alignSelf:"center"}}/>
                          <Grid item><Typography>Remove</Typography></Grid>
                          
                        </Grid>
                       
                    </Grid>
            
            )}
          </ListItem>
        ))}
      </List>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu  navigation={this.props.navigation} id={""}>
        <Container maxWidth="md">
          <Box sx={webStyle.productContainer}>
            <GoBackBox onClick={this.handleGoBack}>
              <ArrowBackIosIcon style={webStyle.arrowIcon} />
              <Typography style={webStyle.goBackText}>Go Back</Typography>
            </GoBackBox>
            <Typography style={webStyle.headingText}>Performed and Facilitated Care</Typography>
            <MapButton data-test-id="openMap" onClick={()=>this.setState({openMap:true})}>Map</MapButton>
            {this.state.error && this.state.selectedPindata ? <Typography sx={{color:"#DC2626",fontSize:"12px",marginTop:0.5}}>Please select the map location</Typography>:null}
            <Box sx={webStyle.checkBoxContainer}>
              {this.state.careActions.map((item, index) => (
                <Box
                  data-test-id="checkBoxId"
                  key={index}
                >
                  <Box sx={webStyle.checkboxContainer}>
                    <Checkbox
                      data-test-id="checkBoxCallID"
                      edge="start"
                      checked={item.checked}
                      value={this.state.selectedCareActionId}
                      onChange={() => this.handleToggle(index)}
                      tabIndex={-1}
                      disableRipple
                      sx={webStyle.bigCheckBox}
                    />
                    <Typography style={webStyle.listItemName}>{item.attributes.name}</Typography>
                  </Box>

                  {item.attributes.input_type === "text_field" && item.checked && (
                    <TextField
                      data-test-id= "changeInputId"
                      variant="outlined"
                      size="small"
                      value={this.state.comment}
                      onChange={this.handleInputChange}
                      placeholder={`Enter ${item.attributes.name}`}
                      fullWidth
                      sx={webStyle.inputFieldDetails}
                    />

                  )}
                  { item.checked &&
                    item.attributes.testing_locations.length > 0 &&
                    this.renderFormForDrugTestingMachine(item.id)}
                </Box>
              ))}
            </Box>
            <Button sx={webStyle.buttonCollection}
              data-test-id="addPictureId"
              onClick={this.handleOpen}
            >Add Picture
            </Button>
            <Button sx={webStyle.buttonCollection}
              data-test-id="successId"
              onClick={this.handleSubmit}
            >Submit
            </Button>
            {this.renderModal()}
            <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="add-picture-dialog"
          >
            <DialogTitle>
              <span style={webStyle.addPictureHeadingText}>Add Picture</span>
              <IconButton
                data-test-id="closeModalTestId"
                aria-label="close"
                onClick={this.handleClose}
                sx={webStyle.addPictureIcon}
              >
                <CloseIcon sx={{fill:"#2D2A24"}}/>
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {this.renderFileList()}
              <label>
              {this.displayResultImage()}
                <Box
                  display="flex"
                  alignItems="center"
                  sx={webStyle.uploadButtonBox}
                  style={{ cursor: 'pointer' }}
                >
                  <Avatar style={webStyle.photoCamera}>
                    <CameraAltOutlinedIcon style={webStyle.cameraIcon} />
                  </Avatar>
                  <Typography variant="body1" style={webStyle.addPictureText}>
                    Add picture
                  </Typography>
                </Box>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={this.handleFileChange}
                />
              </label>

            </DialogContent>
            {this.state.selectedFiles.length > 0 && (
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={webStyle.uploadButton}
                  onClick={this.uploadImages}
                >
                  Upload
                </Button>
              </DialogActions>
            )}
          </Dialog>
          <Dialog sx={{"& .MuiPaper-root": {
      borderRadius: "12px",width: 'calc(100% - 10px)',margin:"10px" },}} data-test-id="dialogOpenMapView" 
            open={this.state.openMap} onClose={()=>this.setState({openMap:false})}
            fullWidth maxWidth="sm" aria-labelledby="add-picture-view-dialogharm">
            <DialogTitle sx={{padding: "3px 15px"}}>
              <span style={webStyle.addPictureHeadingText}>Map</span>
              <IconButton data-test-id="dialogCloseMapView" aria-label="close"
                onClick={()=>this.setState({openMap:false})} 
                sx={webStyle.addPictureIcon} ><CloseIcon sx={{fill:"#2D2A24"}}/> </IconButton>
            </DialogTitle>
            <DialogContent  sx={{padding: "20px 10px"}}>
            <Location sendDataToPage1={this.handleDataFrompin} data={"performed_and_facilitated_care"} navigation={this.props.navigation} id={""} />
             </DialogContent>
          </Dialog>
          <Dialog data-test-id="dialogImageView" 
            open={this.state.openView} onClose={()=>this.handleCloseHarmView}
            fullWidth maxWidth="sm" aria-labelledby="add-picture-view-dialog"
          >
            <DialogTitle>
              <span style={webStyle.addPictureHeadingText}>View Image</span>
              <IconButton
                data-test-id="closeModalTestId" aria-label="close"
                onClick={this.handleCloseHarmView} sx={webStyle.addPictureIcon}
              >
                <CloseIcon sx={{fill:"#2D2A24"}}/> </IconButton>
            </DialogTitle>
            <DialogContent style={{alignContent:"center",alignItems:"center",textAlign:"center",}} dividers>
            {this.state.removeImg? 
              <span>
               <Box sx={{
                backgroundColor: '#FEF3C7',  color: '#D97706',
                padding: '10px 10px',
                borderRadius: '4px',                        display: 'flex',
                alignItems: 'center',                        borderLeft: '4px solid #D97706',
                marginBottom: 3
              }}>
                <Typography variant="body2" textAlign={"left"} color="#D97706" sx={{ fontWeight: "700",margin:1 }}>
                Are you sure you want to remove the image?
                </Typography>
              
              </Box>
                <Grid sx={{alignItems:"center",textAlign:"center"}} container>
                <Grid item xs={6}> 
                    <Button sx={[{paddingLeft:"28%",paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
              textTransform: 'none',  color: "black", 
              font: "Inter", fontSize: "16px", fontWeight: "700",
              height: "44px"
              ,border:"2px red solid",
              '&:hover': {
                backgroundColor: '#E2E8F0',
              },
            }]}
            onClick={this.handleCloseHarmView} 
              data-test-id={"editcancelButton1"}>No </Button></Grid>
                <Grid item xs={6}>
                  <Button sx={[{paddingLeft:"28%", paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
              textTransform: 'none', backgroundColor:"#F5C502", 
              color: "black", font: "Inter", fontSize: "16px", fontWeight: "700",height: "44px",
              '&:hover': {
                backgroundColor: '#E2E8F0',
              },
            }]}

            onClick={()=>this.handleRemoveFile(toInteger(this.state.removeImg))}
              data-test-id={"btnEditmodalButton"}>Yes 
              </Button>
              
                </Grid>
              </Grid> 
              </span>:
            <img
            src={this.state.imageView}
            alt="Uploaded Preview"
            style={{ width: "200px", height: "auto", borderRadius: "8px", alignSelf:"center",textAlign:"center"}}
          />
          }
            </DialogContent>
           
          </Dialog>
          </Box>
        </Container>
      </NavigationMenu>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const GoBackBox = styled(Box)({
  height: "22px",
  width: "78px",
  display:"flex",
  gap:"8px",
  alignItems:"center",
  marginLeft:"5px"
});

const MapButton = styled(Button)({
  height: "44px",
  width: "100%",
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  borderRadius:"8px",
  background:"#F5C502",
  color:"#2D2A24",
  fontSize:"16px",
  fontWeight:700,
  textTransform:"capitalize",
  cursor:"pointer",
  marginTop:"32px",
});

const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    margin: "20px 0px",
  },
  goBackText: {
    color:"#1D4ED8",
    fontSize:"14px",
    fontWeight:400,
  },
  arrowIcon: {
    fontSize:"14px",
    fontWeight:400,
    color:"#2D2A24"
  },
  headingText: {
    color:"#2D2A24",
    fontSize:"24px",
    fontWeight:700,
    marginTop:"16px",
  },
  buttonCollection: {
    marginTop:"24px",
    borderRadius:"8px",
    display:"flex",
    height: "44px",
    width: "100%",
    fontSize:"16px",
    alignItems:"center",
    background:"#F5C502",
    justifyContent:"center",
    color:"#2D2A24",
    cursor:"pointer",
    fontWeight:700,
    textTransform:"capitalize",
    "&:hover":{
      backgroundColor:"#F5C502"
     }
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center", 
  },
  listItemName: {
    fontWeight:400,
    color:"#2D2A24",
    fontSize:"16px",
  },
  subMenulistItemName: {
    fontSize:"14px",
    fontWeight:700,
    color:"#2D2A24",
  },
  checkBoxContainer: {
    margin: "24px 0px 16px 0px",
    width:"100%",
    maxWidth: "100%",
  },
  inputFieldDetails: {
    width:"100%",
    border: "1px solid #CBD5E1",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: "8px",
    '& .MuiOutlinedInput-root': {
      borderRadius: "8px",
      '& fieldset': {
        borderColor: "transparent",
      },
      '&:hover fieldset': {
        borderColor: "transparent",
      },
      '&.Mui-focused fieldset': {
        borderColor: "transparent",
      },
    }
  },
  subCheckBoxContainer: {
    display:"flex",
    alignItems:"center",
    borderBottom:"1px solid #CBD5E1",
    height:"44px",
  },
  subCkeckBox: {
    color: "#64748B",
    borderRadius: "6px",
    paddingLeft:"32px",
    '&.Mui-checked': {
      color: "#CF9500",
    },
    '& .MuiSvgIcon-root': {
      fontSize: "16px",
    },
  },
  lableText: {
    fontWeight: 700,
    fontSize:"16px",
    color:"#2D2A24",
    marginBottom:"6px",
  },
  subBoxinputField: {
    marginBottom:"12px",
    fontSize: "16px",
    width:"100%",
    color: "#94A3B8",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    fontWeight: 400,
    '& .MuiOutlinedInput-root': {
      borderRadius: "8px",
      '& fieldset': {
        borderColor: "transparent",
      },
      '&.Mui-focused fieldset': {
        borderColor: "transparent",
      },
      '&:hover fieldset': {
        borderColor: "transparent",
      },
    }
  },
  modalHeading: {
    width:"80%",
    fontWeight: 700,
    color:"#2D2A24",
    fontSize:"24px", 
  },
  uploadImageBox: {
    cursor: "pointer",
    gap: "16px",
    boxShadow: "0px 2px 4px 0px #00000026",
    padding: "8px",
    display:"flex",
    alignItems:"center" 
  },
  bigCheckBox: {
    color: "#64748B",
    borderRadius: "6px",
    '&.Mui-checked': {
      color: "#CF9500",
    },
    '& .MuiSvgIcon-root': {
      fontSize: "20px",
    },
  },
  addPictureHeadingText: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#2D2A24"
  },
  addPictureIcon: {
    position: 'absolute',
    right: "4.51px",
    top: "4.51px"
  },
  uploadButtonBox: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
    marginBottom:"5px"
  },
  pendingImageBox: {
    height: "48px",
    width: "48px",
    borderRadius: "6px",
    backgroundColor: '#FFFDC1'
  },
  defaultImageIcon:{
    color:"#F5C502",
    fontSize: "20px"
  },
  photoCamera: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  cameraIcon: {
    color: "#2D2A24",
    fontSize: "24px"
  },
  addPictureText: {
    marginLeft: '16px',
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:400
  },
  uploadButton: {
    backgroundColor: '#F5C502',
    height:"44px",
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:700,
    textTransform:"capitalize",
  },
};
// Customizable Area End
