import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
interface Service{
  [x: string]: unknown;
  name: any;
  checked: boolean | undefined;
  id: string,
  type: string,
  checkedIds:boolean,
  attributes: {
    tasks_lists: TaskList[];
      name: string,
      service_offered_name: string,
      created_at: string,
      updated_at: string,
      checked: boolean;
  }
}
interface  TaskList  {
  id: string;
  type: string;
  attributes: {
    name: string;
    created_at: string;
    updated_at: string;
    tasks: {
      id: string;
      name: string;
      input_type: string;
      created_at: string;
      updated_at: string;
    }[];
  };
};
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface Item {
  id: number;
  name: string;
  input_type:string;
  count:number | string
}
interface Result {
  [key: string]: {
    label: string;
    options?: {
    };
  };
}
interface Resultmed {
  [key: string]: {
    label: string;
    value:string,
    
  };
}
interface Resultmental {
  [key: string]: {
    label: string;
    
  };
}
interface Org{
  
    data: {
        id: string,
        type: string,
        attributes: {
            organization_name: string,
            address: string,
            contact_person:string,
            organization_phone: string,
            organization_email: string
        }
    },
    relationships: {
        sub_services: {
            data: []
        }  }

}
interface NewOrg{
  id:string,
  organization_name:string
}
interface Orgref{
  address:string,
contact_person: string
created_at:string, 
id:number,
organization_email: string,
organization_name:string,
organization_phone:string,
updated_at:string
}
interface OrgDetails{
  organization:{
    id:string,
    attributes:{
      organization_name:string,
      contact_person:string,
      organization_phone:string,
      address:string
    }
  },
  is_bed_availble:boolean | undefined
}
interface Medical{
  value: string;
  label: string;
  checked: boolean;
  options: any;
}
interface Mental{
  value: string;
  label: string;
  checked: boolean;
}
interface AnyObject {
  [key: string]: any;
}
interface HealthcareAccessAndFacilitation  {
  reserve_bed_id?: number;
  additional_items?: string;
  lat: number;
  lng: number;
  image_ids:number[],
  map_form_icons_id: number
  selected_substances?: any[]; 
  selected_medical_care?: any[]; 
  selected_mental_health?: any[]; 
  selected_support?: any[]; 
};
// Customizable Area End

interface S {
  // Customizable Area Start
  selectedService: string;
  allServices:Service[],
  token:string,
  openReserveBed:boolean,
  selectedBedService:Service[],
  selectedItems: any;
  options:string,
  hasIDR:string,
  sudR:string,
  hasID:string,
  sud:string,
  orgnizationData:Org[],
  openReferral:boolean,
  selectedOrg:{data:{attributes:{organization_name:string}}},
  formId: string,
  formType: string,
  showEdit:boolean,
  open: boolean,
  selectedFiles: File[];
  success:boolean,
  uploaded:boolean,
  subTitle:string,
  isDropdownOpen:number,
  openView:boolean,
  removeImg:string,
  imageView:string,
  subservice:string,
  referralorg:NewOrg[],
  selectedRefOrg:Orgref,
  refOrgDetails:OrgDetails,
  reserveBedId:number,
  additionalItem:string,
  openMap:boolean,
  lat:number,
  lang:number,
  selectedPin:{
    icon:string,
    id:number,
    name:string
  },
  image_ids: number[],
  openDrop:boolean,
  services:[],
  medicalCare:Medical[],
  selectedMedical:any,
  mentalHealth:Mental[],
  support:Medical[],
  selectedMentalHealth:any,
  selectedSupport:any,
  healthcare_access_and_facilitation:any,
  successModal:boolean,
  successWarm:boolean,
  errorpin:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class HealthcareAccessFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAllServicesCallId:string=""
  apiGetOrgCallId: string="";
  reserveBedCallId: string="";
  apiCallIdGetHealthcareFormDetails: string = "";
  uploadImageCallIdHarm: string="";
  getorganisationListApiCallId: string="";
  apiGetreferralORGDetailCallId: string="";
  apiReserveBedCallId: string="";
  apiCreateFormCallId: string="";
  getsubstanceListApiCallId: string="";
  getmedicalListApiCallId: string="";
  getmentalHealthListApiCallId: string="";
  getsupportListApiCallId: string="";
  apiCallIdEditwarmForm: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedService: "",
      successModal:false,
    allServices:[],
    token:"",
    openReserveBed:false,
    selectedBedService:[],
    selectedItems:{},
    options:"",
    hasIDR:"",
    sudR:"",
    hasID:"",
    sud:"",
    orgnizationData:[],
    openReferral:false,
    selectedOrg:{data:{attributes:{organization_name:""}}},
    formId: "",
    formType: "",
    showEdit:false,
    open: false,
    selectedFiles: [],
    success:false,
    uploaded:false,
    subTitle:"",
    isDropdownOpen:0,
    openView:false,
    removeImg:"",
    imageView:"",
    subservice:"",
    referralorg:[],
    selectedRefOrg:{
      address: "",
      contact_person: "",
      created_at: "",
      id: 0,
      organization_email: "",
      organization_name: "",
      organization_phone: "",
      updated_at: ""
    },
    refOrgDetails:{
      organization: {
        id: "",
        attributes: {
          organization_name: "",
          contact_person: "",
          organization_phone: "",
          address: ""
        }
      },
      is_bed_availble: undefined
    },
    reserveBedId:0,
    additionalItem:"",
    openMap:false,
    lat:0,
    lang:0,
    selectedPin:{
    icon:"",
    id:0,
    name:"",
    
    },
    image_ids: [],
    openDrop:false,
    services:[],
    medicalCare:[],
    selectedMedical:{},
    mentalHealth:[],
    selectedMentalHealth:{},
    support:[],
    selectedSupport:{},
    healthcare_access_and_facilitation:{},
    successWarm:false,
    errorpin:false
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

 

  
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) { 
      if (apiRequestCallId ===  this.apiGetAllServicesCallId) {
      
        
          
          this.setState({services:responseJson.data})
       
        
    }
      if   (apiRequestCallId ===  this.apiGetOrgCallId){
        this.setState({orgnizationData:responseJson})
      }
      if(apiRequestCallId === this.apiCallIdGetHealthcareFormDetails) {
        console.log("popppppppppp44444", responseJson)
        this.loadForm(responseJson)
      }
      if( apiRequestCallId ===this.uploadImageCallIdHarm){
            this.handleImageUploadHarm(responseJson);
      }
      switch (apiRequestCallId) {
        case this.getorganisationListApiCallId:
          this.setState({ referralorg: responseJson.organizations });
          break;
      
        case this.apiGetreferralORGDetailCallId:
          this.setState({ refOrgDetails: responseJson });
          break;
      
        case this.apiReserveBedCallId:
          this.setState({
            reserveBedId: responseJson.data.id,
            openReferral: false,
          });
          break;
      
        case this.getsubstanceListApiCallId:
          this.setState({
            allServices: responseJson.data.map((item: any) => {
              const key = Object.keys(item)[0];
              const service = item[key];
              return {
                value: key,
                label: service.label,
                checked: false,
                options: service.options ,
              };
            }),
          });
          break;
      
        case this.getmedicalListApiCallId:
          this.setState({
            medicalCare: responseJson.data.map((item: any) => {
              const key = Object.keys(item)[0];
              const service = item[key];
              return {
                key,
                value: service.value,
                label: service.label,
                checked: false,
              };
            }),
          });
          break;
      
        case this.getmentalHealthListApiCallId:
          this.setState({
            mentalHealth: responseJson.data.map((item: any) => {
              const key = Object.keys(item)[0];
              const service = item[key];
              return {
                key,
                label: service.label,
                checked: false,
              };
            }),
          });
          break;
      
        case this.getsupportListApiCallId:
          this.setState({
            support: responseJson.data.map((item: any) => {
              const key = Object.keys(item)[0];
              const service = item[key];
              return {
                key,
                label: service.label,
                checked: false,
              };
            }),
          });
          break;
      
        case this.apiCreateFormCallId:
          this.setState({successModal: true})
          if (responseJson) {
            this.displayResponse()
            
          }else{
            this.setState({successWarm:false,subTitle:"Please try again later"})
          }
          break;
      
        default:
          console.log("error")
          break;
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  displayResponse=()=>{
    this.setState({ successWarm:true,subTitle:this.state.formId?"Form Updated":"Form Submitted" });
  }
  async componentWillUnmount() {
    removeStorageData("formId")
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
   
    const formid = await getStorageData("formId");
    const formtype = await getStorageData("formType");
    const edit = await getStorageData("showEdit");
    this.setState({
      formId: formid,
      formType: formtype,
      showEdit: edit
    });
    if(formid){
      this.getFormDetails()
    }
   
  }
  getFormDetails = () => {

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
       token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdGetHealthcareFormDetails = requestMessage.messageId;
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_custom_forms/update_submitted_forms/${this.state.formId}?form_type=${this.state.formType}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return this.apiCallIdGetHealthcareFormDetails;
};

  getToken = async () => {
    const token = await getStorageData("token")
    this.setState({token:token},()=>this.getAllServices(),)
  };
  getAllServices = () => {
    this.getOrganiszationAll()
    this.getsubstanceList()
    this.getmedicalList()
    this.getmentalHealthList()
    this.getsupportList()
    const header = {
      token:this.state.token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetAllServicesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllServices
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodapi
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
 
  

handleTogglemedical=( selectedSubService: { label: string; })=>{
  
  this.setState((prevState) => {
     
    const updatedServices = prevState.medicalCare.map((option) =>
      option.label === selectedSubService.label
        ? { ...option, checked: !option.checked } 
        : { ...option, checked: false } 
    );
  
  
    const updatedSelectItem = updatedServices.find(
      (option) => option.label === selectedSubService.label && option.checked
    )|| {};
  
    return {
      ...prevState, 
      medicalCare: updatedServices, 
      selectedMedical: updatedSelectItem, 
    };
  });
}
handleToggleSupport=( selectedSubService: { label: string; })=>{
  
  this.setState((prevState) => {
     
    const updatedServices = prevState.support.map((option) =>
      option.label === selectedSubService.label
        ? { ...option, checked: !option.checked } 
        : { ...option, checked: false } 
    );
  
  
    const updatedSelectItem = updatedServices.find(
      (option) => option.label === selectedSubService.label && option.checked
    )||{};
  
    return {
      ...prevState, 
      support: updatedServices, 
      selectedSupport: updatedSelectItem, 
    };
  });
}
handleTogglementalHealth=( selectedSubService: { label: string; })=>{
  
  this.setState((prevState) => {
     
    const updatedServices = prevState.mentalHealth.map((option) =>
      option.label === selectedSubService.label
        ? { ...option, checked: !option.checked } 
        : { ...option, checked: false } 
    );
  
  
    const updatedSelectItem = updatedServices.find(
      (option) => option.label === selectedSubService.label && option.checked
    ) || {};
  
    return {
      ...prevState, 
      mentalHealth: updatedServices, 
      selectedMentalHealth: updatedSelectItem, 
    };
  });
}
  handleToggle = ( selectedSubService: { value: string; }) => {
    this.setState((prevState) => {
     
      const updatedAllServices = prevState.allServices.map((option) =>
        option.value === selectedSubService.value
          ? { ...option, checked: !option.checked } 
          : { ...option, checked: false } 
      );
    
      const updatedSelectItem = updatedAllServices.find(
        (option:any) => option.value === selectedSubService.value && option.checked 
      )|| {};
      console.log(updatedSelectItem,"updatedSelectItem")
      return {
        ...prevState, 
        allServices: updatedAllServices, 
        selectedItems: updatedSelectItem, 
      };
    },()=>this.getReferralsList());
   
};
getOrganiszationAll = () => {
   
  const header = {
    token:this.state.token
  }



  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.apiGetOrgCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getOrgApiENdPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.methodapi
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);

  return true;
};

handleOpenHarm = () => {
  this.setState(
    { open: true }
  );
};

handleCloseHarm = () => 
  {
  this.setState(
    { open: false }
  );
};

handleFileChangeHarm = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
  const files = event.target.files;
  if (files) {
    this.setState((prevState) => (
      {
      selectedFiles: [...prevState.selectedFiles, ...Array.from(files)],
    }
  ));
  }
};

uploadImagesHarm = () => {
  const { selectedFiles } = this.state;
  const formData = new FormData();
  selectedFiles.forEach((file) => 
    {
    formData.append(
      "blob[]", new File([file as any], file.name, { type: "image/png" })
    ); 
  });
  const headersHarm = { token: this.state.token, };
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.uploadImageCallIdHarm = requestMessage.messageId;
  requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.addPictureEndPoint
  );
  requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headersHarm)
  );
  requestMessage.addData( getName(MessageEnum.RestAPIRequestBodyMessage), formData
  );
  requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.formAPiMethod );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleImageUploadHarm = (responseJson:any) => {
 
    if (responseJson && responseJson.message === "Images uploaded successfully") {  
      const uploadedBlob1 = responseJson.blobs;
      const uploadedImageId1 = responseJson.blobs.map((blob: { id: any; }) => blob.id)
      this.setState((prevState) => ({
        image_ids: [...prevState.image_ids, uploadedImageId1], 
      }));
      this.setState((prevState) => ({
        ...prevState,
        // image_ids:  uploadedImageId1,
        selectedFiles: prevState.selectedFiles.map((file) =>
          file.name === uploadedBlob1.filename ? { ...file, status: "uploaded" } : file
        ) 
      }));
      this.setState({selectedFiles:[],success:true,subTitle:"Image upload is successful",uploaded:true})
      setTimeout(() => {
        this.setState({ success: false }); 
      }, 2000);
    } 
    else{
      this.setState({success:true,subTitle:responseJson.errors[0].message,uploaded:false})
    }

  };

  goback=async (page:string)=>{
    await removeStorageData("showEdit")
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  viewImageHarm(file: File,index:number){
    this.setState({isDropdownOpen:index+1})
    const reader = new FileReader();
      reader.onload = () => {
         this.setState({imageView:reader.result as string})
      };
      reader.readAsDataURL(file); 
  }
  handleCloseHarmView = () => {
    this.setState({openView:false,success:false, isDropdownOpen:0, removeImg:"" });
  };
  handleRemoveFile = (index: number) => {
    this.setState({isDropdownOpen:0, openView:false,removeImg:"",imageView:""})
    this.setState((prevState) => ({
      selectedFiles: prevState.selectedFiles.filter((_, i) => i !== index),
    
    }));
  };
  getIdByName = (selectedItemLabel: string) => {
    const { services } = this.state;
  
    const serviceitem:any = services.find(
      (item: {id:number,attributes:{name:string}}) => item.attributes.name === selectedItemLabel
    );
    return serviceitem ? serviceitem.id : null; 
  };
  getReferralsList = () => {
    const selectedServiceId = this.getIdByName(this.state.selectedItems?.label);
    this.setState({subservice:selectedServiceId})
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getorganisationListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReferralList+selectedServiceId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleSelectChange = (event: any) => {
      
    this.setState({ selectedRefOrg:event },()=>this.getBedAvaialbility());
  };
  getBedAvaialbility= () => {
    const header = {
      token:this.state.token
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiGetreferralORGDetailCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBedData+this.state.subservice+"&id="+this.state.selectedRefOrg.id
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodapi
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  };
  reserveBed=()=>{
   
    const httpBody = { reserve_bed :{
      organization_id: parseInt(this.state.refOrgDetails.organization?.id),
      sub_service_id: parseInt(this.state.subservice),
      has_id: this.state.hasID==="Yes"? true:false,
      sud: this.state.sud.toLocaleLowerCase()
  }};
    const header = {
      "Content-Type": "application/json",
      token:this.state.token
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.apiReserveBedCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reserveBedApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
  };
  removeEmptyArrays(obj: AnyObject): AnyObject {
    const result: AnyObject = {};

    for (const key in obj) {
      const value = obj[key];

    
      if (Array.isArray(value)) {
        this.processArray(result, key, value);
      } else if (this.isNonNullObject(value)) {
        this.processObject(result, key, value);
      } else {
        result[key] = value;
      }
    }

    return result;
  }

   processArray(result: AnyObject, key: string, value: any[]) {
    const filteredArray = value.filter(item => this.shouldKeepItem(item));
    if (filteredArray.length > 0) {
      result[key] = filteredArray;
    }
  }

   processObject(result: AnyObject, key: string, value: AnyObject) {
    const cleanedObject = this.removeEmptyArrays(value);
    if (Object.keys(cleanedObject).length > 0) {
      result[key] = cleanedObject;
    }
  }

  
   shouldKeepItem(item: any): boolean {
    return !this.isNonNullObject(item) || Object.keys(this.removeEmptyArrays(item)).length > 0;
  }

  
   isNonNullObject(value: any): boolean {
    return typeof value === 'object' && value !== null;
  }
  getEndPoint=()=>{
    if(this.state.formId){ 
      
      return `bx_block_custom_forms/update_submitted_forms/${this.state.formId}?form_type=${this.state.formType}`
     }else{

     return configJSON.createWarmHandsApiEndPoint
     }

  }
  getMethod=()=>{
if(this.state.formId){
   return "PUT" }
   else{ return configJSON.addUserApiMethod}
}
  getOptions=()=>{
    return this.state.options==="Yes"?true:false
  }
  getHasIDR=()=>{
    return this.state.hasIDR==="Yes"?true:false
  }
  gethasid=()=>{
    return this.state.hasID==="Yes"?true:false
  }
  createWarmHandsOff=()=>{
    
    const result: Result = this.state.allServices
    .filter(service => service.checked) 
    .reduce((acc, service:any) => {
      const key = service.value as string; 
      const option=service.label==="Rehabilitation Center"?{
        completed_detox_form:this.getOptions(),
        has_id: this.getHasIDR(),
        sud_oud: this.state.sudR
      }:
        {has_id: this.gethasid(),
          sud: this.state.sud}
      
      if(["Rehabilitation Center", "Detox Programs"].includes(service.label)){
        acc[key] = {
          label: service.label,
          options: option
        };
      } else{
        acc[key] = {
          label: service.label,
        };
      }
      
      return acc;
    }, {} as Result);

    const resultmed: Resultmed = this.state.medicalCare
    .filter(service => service.checked) 
    .reduce((acc, service:any) => {
      const key = service.key as string; 
      acc[key] = {
        label: service.label,
        value:service.value
      };
      return acc;
    }, {} as Resultmed);

    const resultmental: Resultmental = this.state.mentalHealth
    .filter(service => service.checked) 
    .reduce((acc, service:any) => {
      const key = service.key as string; 
      acc[key] = {
        label: service.label,
      };
      return acc;
    }, {} as Resultmental);

    
    const resultsupport: Resultmed = this.state.support
    .filter(service => service.checked) 
    .reduce((acc, service:any) => {
      const key = service.key as string; 
      acc[key] = {
        label: service.label,
        value:service.value
      };
      return acc;
    }, {} as Resultmed);

    
    const  healthcare_access_and_facilitation:HealthcareAccessAndFacilitation ={
        lat: this.state.lat,
        lng: this.state.lang,
        image_ids:this.state.image_ids.flat(),
        map_form_icons_id:this.state.selectedPin.id,
        selected_substances: [result],
        selected_medical_care: [resultmed],
        selected_mental_health: [resultmental],
        selected_support: [resultsupport],
  }
  let datatosend:any ={
    data:this.removeEmptyArrays(healthcare_access_and_facilitation)
  }
  if (this.state.reserveBedId) {
    datatosend.data.reserve_bed_id = this.state.reserveBedId;
  }
  if(this.state.additionalItem){
    datatosend.data.additional_items= this.state.additionalItem
  }
  const httpBody = datatosend;
    const header = {
      "Content-Type": "application/json",
      token:this.state.token
    }
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  let endpoint = this.getEndPoint()
    this.apiCreateFormCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
let method = this.getMethod()
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
   if(this.state.selectedPin.id){
    runEngine.sendMessage(requestMessage.id, requestMessage);
  
    return true;
   }else{
    this.setState({errorpin:true})
   }
    
  };
  handleDataFromPage = (data: {icon:string,id:number,name:string},lattitude:number,lang:number) => {
   
    this.setState({ selectedPin: data,lat:lattitude,lang:lang,errorpin:false }); 
  };
  handleClose=()=>{
    this.setState({openDrop:false})
  }
  handleOpen=()=>{
    this.setState({openDrop:true})
  }
  getsubstanceList = () => {

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getsubstanceListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createWarmHandsApiEndPoint+"/get_substances"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getmedicalList = () => {

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getmedicalListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createWarmHandsApiEndPoint+"/get_medical_care"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getmentalHealthList = () => {

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getmentalHealthListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createWarmHandsApiEndPoint+"/get_mental_health"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getsupportList = () => {

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getsupportListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createWarmHandsApiEndPoint+"/get_support"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleTextChange = (value: string, text: string) => {
    this.setState((prevState) => ({
      medicalCare: prevState.medicalCare.map((option) =>
        option.label === value 
          ? ({ ...option, value: text }) 
          : option 
      ),
      selectedMedical:{...prevState.selectedMedical,value:text}
    }));
  };
  handleTextChangeSupport = (value: string, text: string) => {
    this.setState((prevState) => ({
      support: prevState.support.map((option) =>
        option.label === value 
          ? ({ ...option, value: text }) 
          : option 
      ),
      selectedSupport:{...prevState.selectedSupport,value:text}
    }));
  };
  handleCloseModalharm = () => {

    this.setState({ successModal: false });
    this.goback("LandingPage")
  };
  setSubstancedata=(apisubstanceuseLabel: {
    label: string; options: {
      sud: string;
    completed_detox_form: any;
    sud_oud: string; has_id: any; 
}; }[])=>{
  if(apisubstanceuseLabel[0].label==="Rehabilitation Center"){
    this.setState({options: apisubstanceuseLabel[0].options.completed_detox_form?"Yes":"No",sudR: apisubstanceuseLabel[0].options.sud_oud,hasIDR:apisubstanceuseLabel[0].options.has_id?"Yes":"No" });
  }else{
    this.setState({sud: apisubstanceuseLabel[0].options.sud, hasID:apisubstanceuseLabel[0].options.has_id?"Yes":"No" });
  }
  }
  updateMedicalArray = (
    apiResponse: { data: any; }) => {
    const apiMedicalLabels = (apiResponse?.data?.attributes?.medical_care ); 
    const apiSupportLabels = (apiResponse?.data?.attributes?.support ); 
    const apisubstanceuseLabel=(apiResponse?.data?.attributes?.substance_use)
    const apiMentalLabel = (apiResponse?.data?.attributes?.mental_health)
    
    let subtance=  this.state.allServices?.map((item:any) => {

      const isChecked = apisubstanceuseLabel?.some((label: { label: string; }) => {
        return label.label.toLowerCase().trim() === item.label.toLowerCase().trim();
      });
      
    
      if(isChecked){
      
        if (apisubstanceuseLabel[0].options) {
          this.setSubstancedata(apisubstanceuseLabel)
          
        }
        item.checked=true
        this.setState({selectedItems: item})
        return item
        
      }else{return item}
    
    });
    this.setState({allServices:subtance})
    let support=  this.state.support?.map((item) => {

      const isChecked = apiSupportLabels?.some((label: { label: string; }) => {
        return label.label.toLowerCase().trim() === item.label.toLowerCase().trim();
      });
      
    
      if(isChecked){
        item.checked=true
        item.value=apiSupportLabels[0].value
        this.setState({selectedSupport: item})
        return item
        
      }else{return item}
    
    });
    this.setState({support:support})

let medical=  this.state.medicalCare?.map((item) => {

  const isChecked = apiMedicalLabels?.some((label: { label: string; }) => {
    return label.label.toLowerCase().trim() === item.label.toLowerCase().trim();
  });
  

  if(isChecked){
    item.checked=true
    item.value=apiMedicalLabels[0].value
    this.setState({selectedMedical: item})
    return item
    
  }else{return item}

});
this.setState({medicalCare:medical})
let mental=  this.state.mentalHealth?.map((item) => {

  const isChecked = apiMentalLabel?.some((label: { label: string; }) => {
    return label.label.toLowerCase().trim() === item.label.toLowerCase().trim();
  });
  

  if(isChecked){
    item.checked=true
    item.value=apiMentalLabel[0].value
    this.setState({selectedMentalHealth: item})
    return item
    
  }else{return item}

});
this.setState({mentalHealth:mental})
 
  };
  loadForm=(apiResponse: { data: { attributes: {longitude:number,latitude:number,icon:string,map_form_icons_id:number,map_form_icon_name:string, reserve_bed: { id: number; }; additional_items: string; lat: number; lng: number; }; }; })=>{
    if(apiResponse)
  this.setState({
    reserveBedId:apiResponse?.data?.attributes?.reserve_bed?.id || 0,
    additionalItem:apiResponse?.data?.attributes?.additional_items,
    lat:apiResponse?.data?.attributes?.latitude,
    lang:apiResponse?.data?.attributes?.longitude,
    selectedPin:{
      icon:apiResponse?.data?.attributes?.icon,
      id:apiResponse?.data?.attributes?.map_form_icons_id,
      name:apiResponse?.data?.attributes?.map_form_icon_name
    },
    
  })
this.updateMedicalArray(apiResponse);

  }
 
  // Customizable Area End
}
