import React from "react";
// Customizable Area Start
import {  
  Box,
  Button,
  Typography,
  MenuItem,
  FormControl,
  Select,
  TextField,Checkbox,
  Dialog,
  IconButton, DialogTitle,
  DialogContent,
  Avatar,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Grid,
  Divider,
  Input
   } from "@mui/material";
   import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
   import WallpaperIcon from '@mui/icons-material/Wallpaper';
import {

  styled,
  
} from "@mui/material/styles";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {  Close as CloseIcon } from "@mui/icons-material";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
const TextContainer = styled(Box)({
  height:"auto",
  backgroundColor: '#f0f0f0',
  padding:"16px",
});
import { View } from "react-native";
import Location from "../../location/src/Location.web";
// Customizable Area End

import HealthcareAccessFormController, { Props } from "./HealthcareAccessFormController.web";
import { toInteger } from "lodash";




export default class HealthcareAccessForm extends HealthcareAccessFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  title=()=>{
    return(
      <span>
         {this.state.removeImg?<span style={webStyles.addPictureHeadingText}>Remove Image</span>:
              <span style={webStyles.addPictureHeadingText}>View Image</span>}
      </span>
     
    )
  }
  renderModal = () => (
    <Dialog
      open={this.state.successModal}
      onClose={this.handleCloseModalharm}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        <Typography sx={webStyles.modalHeading}>
        Referrals and Warm Hand Offs
        </Typography>
        <IconButton
        data-test-id="btnhandleCloseModalharm"
          onClick={this.handleCloseModalharm}
          sx={{ position: 'absolute', right: 8, top: 2,fill:"#2D2A24" }}
        >
          <CloseIcon sx={{fill:"#2D2A24"}}/>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center', paddingY: 4 }}>
      {this.state.successWarm? <CheckCircleOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "#059669",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> 
          :<ErrorOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "red",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> }
        <Typography variant="h6" sx={webStyles.arrowIcon}>
        {this.state.subTitle}
        </Typography>
      </DialogContent>
    </Dialog>
  )
  displayResult = ()=>   {
    return(
    this.state.success &&
    <Box sx={{
      backgroundColor:this.state.uploaded ? '#ECFDF5':"#FEE2E2",  color: '#D97706',
      padding: '10px 10px',
      borderRadius: '4px',                        display: 'flex',
      alignItems: 'center',                        
      borderLeft:this.state.uploaded ?  '5px solid #059669':'5px solid #DC2626',
      marginBottom: 3
    }}>
      <Typography variant="body2" textAlign={"left"} color={this.state.uploaded ? "#2D2A24":"#DC2626"} sx={{ fontWeight: "400",margin:1 }}>
      {this.state.subTitle}
      </Typography>
    </Box>
     )} 
  renderFileListHarm = () => {
    return (
      <List>
        {this.state.selectedFiles?.map((file, index) => (
          <ListItem 
          key={index} 
          divider 
          sx={webStyles.uploadButtonBox1}
          >
            <ListItemAvatar>
              <Avatar 
              style={webStyles.pendingImageBox}>
                <ImageOutlinedIcon 
                style={webStyles.defaultImageIcon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText 
           primaryTypographyProps={{
            noWrap: true, style: {
              overflow: "hidden", 
              textOverflow: "ellipsis", whiteSpace: "nowrap", 
            },
          }}
            primary={file.name} 
            secondary={`${(file.size / 1024).toFixed(2)} KB`}
            />
            <IconButton data-test-id="btnOpenImage" onClick={()=>this.viewImageHarm(file,index)}
            edge="end"> 
            <MoreVertOutlinedIcon />
            </IconButton>
            {this.state.isDropdownOpen==index+1 &&(
                      <Grid 
                      sx={webStyles.dropUI}
                    > <Grid container
                          style={webStyles.dropContainer}
                          data-test-id="btnOpenImageView"
                          onClick={()=>this.setState({openView:true,isDropdownOpen:0})}
                        ><WallpaperIcon style={{ fill: "#2D2A24",marginRight:5,alignContent:"center",alignItems:"center",alignSelf:"center"}}/>
                          <Grid item><Typography>View</Typography></Grid>
                        </Grid>

                        <Grid container style={{
                            padding: "8px 16px",cursor: "pointer",
                            display: "flex",alignItems: "center",
                            fontFamily:"Inter",gap: "8px",
                          }}
                          data-test-id="btnOpenImageRemovew" onClick={()=>this.setState({removeImg:index.toString(),openView:true})}
                        >
                         <DeleteOutlineOutlinedIcon style={{ fill: "#2D2A24",marginRight:5,alignContent:"center",alignItems:"center",alignSelf:"center"}}/>
                          <Grid item>
                            <Typography>
                              Remove</Typography>
                          </Grid></Grid>  </Grid>
            )}
          </ListItem>
        )
      )
        }
      </List>
    );
  };
  rehabOption = (option: { checked: boolean }) => {
    const { selectedItems, sud, hasID, options, hasIDR, sudR } = this.state;
  
    const renderSelect = (value: string, placeholder: string, options: any[], onChange: { (val: any):void }, fullWidth = false) => (
      <Select
        fullWidth
        value={value} data-test-id="renderSelect"
        displayEmpty
        IconComponent={ExpandMoreIcon}
        onChange={(e) => onChange(e.target.value)}
        sx={{ minWidth: 123,
          height: 44,
          gap: "8px",
          borderRadius: "8px",
          borderWidth: "1px",
          paddingTop: "10px",
          paddingRight: "8px",
          paddingBottom: "10px",
          paddingLeft: "8px",
          
          color: value ? "inherit" : "#CBD5E1",
          "& .MuiSelect-select": {
            color: value ? "inherit" : "#CBD5E1",
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: "1px solid #CBD5E1",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#CBD5E1"
          },
        }}
      MenuProps={{
        PaperProps:{
        height: 88,
        borderRadius: "8px",
        },
        
      }}
        renderValue={(selected) => selected || placeholder}
      >
        {options.map((opt ,index) => (
          <MenuItem sx={{height:"44px",fontSize:"14px",fontWeight:700,borderBottom: index === options.length - 1 ? 'none' : '1px solid #CBD5E1'}} key={opt} value={opt}>{opt}</MenuItem>
        ))}
      </Select>
    );
  
    const renderReferralButton = () => (
      <Box sx={{  width: "95%",                
        display: "flex",
        justifyContent: "center",}}>
        <Button fullWidth
          data-test-id="submitBtnrenderReferralButton1"
          onClick={() => this.setState({ openReferral: true })}
          sx={[webStyles.referralButton,{  width: "calc(100% - 20px)", 
            margin: "0 10px",}]}
        >
          Referral
        </Button>
      </Box>
    );
    const renderReferralButtonRehab = () => (
      <Box sx={{  width: "95%",                
        display: "flex",
        justifyContent: "center",}}>
        <Button disabled={this.state.options!=="Yes"}
        fullWidth
          data-test-id="submitBtnrenderReferralButton"

          onClick={() => this.setState({ openReferral: true })}
          sx={[webStyles.referralButton,{  width: "calc(100% - 20px)", 
            margin: "0 10px", backgroundColor: this.state.options==="Yes"?'#F5C502':"#E2E8F0",}]}> Referral </Button>
      </Box>
    );
  
    const renderRehabilitationCenter = () => (
      <Box sx={[webStyles.itemsMainBox, { width: "100%",flexDirection:"column" }]}>
        <Grid container flexDirection="column">
          <Grid container item flexDirection="row" sx={{ alignItems: "center" }}>
            <Grid item xs={7}>
              <Typography sx={{ textAlign: "left", marginLeft: 3, }} style={webStyles.listItemName}>Completed Detox Form?</Typography>
            </Grid>
            <Grid item xs={4} sx={{ paddingRight: 6, paddingY: 1 }}>
              {renderSelect(options, "Y/N", ["Yes", "No"], (val: any) => this.setState({ options: val }))}
            </Grid>
          </Grid>
          <Grid container item flexDirection="row" sx={{ alignItems: "center" }}>
            <Grid item xs={7}>
              <Typography sx={{ textAlign: "left", marginLeft: 3, }} style={webStyles.listItemName}>Has ID?</Typography>
            </Grid>
            <Grid item xs={4} sx={{ paddingRight: 6, paddingY: 1 }}>
              {renderSelect(hasIDR, "Y/N", ["Yes", "No"], (val: any) => this.setState({ hasIDR: val }))}
            </Grid>
          </Grid>
          <Grid container item flexDirection="row" sx={{ alignItems: "center", marginBottom: 2 }}>
            <Grid item xs={7}>
              <Typography sx={{ textAlign: "left",marginLeft:3 }} style={webStyles.listItemName}>SUD/OUD</Typography>
            </Grid>
            <Grid item xs={4} sx={{ paddingRight: 6, paddingY: 1 }}>
              {renderSelect(sudR, "Alcohol / Opioid", ["Alcohol", "Opioid"], (val: any) => this.setState({ sudR: val }), true)}
            </Grid>
          </Grid>
        </Grid>
        {renderReferralButtonRehab()}
      </Box>
    );
  
    const renderDetoxPrograms = () => (
      <Box sx={[webStyles.itemsMainBox, { width: "100%", flexDirection: "column" }]}>
        <Grid sx={{ marginLeft: 3 }} container flexDirection="column">
          
         
          <Grid container item flexDirection="row" sx={{ alignItems: "center", marginBottom: 2 }}>
            <Grid item xs={3}>
              <Typography sx={{ textAlign: "left",marginLeft:1.5 }} style={webStyles.listItemName}>Has ID?</Typography>
            </Grid>
            <Grid item xs={9} sx={{ paddingRight: 4, paddingTop: 1 }}>
              {renderSelect(hasID, "Y/N", ["Yes", "No"], (val: any) => this.setState({ hasID: val }), true)}
            </Grid>
          </Grid>
    
          
          <Grid container item flexDirection="row" sx={{ alignItems: "center", marginBottom: 2 }}>
            <Grid item xs={3}>
              <Typography sx={{ textAlign: "left",marginLeft:1.5 }} style={webStyles.listItemName}>SUD</Typography>
            </Grid>
            <Grid item xs={9} sx={{ paddingRight: 4, paddingBottom: 1 }}>
              {renderSelect(sud, "Alcohol / Opioid", ["Alcohol", "Opioid"], (val: any) => this.setState({ sud: val }), true)}
            </Grid>
          </Grid>
    
        </Grid>
    
        {renderReferralButton()}
      </Box>
    );
  
    if (!option.checked) return null;
  
    return (
      <Box>
        {selectedItems?.label === "Rehabilitation Center" && renderRehabilitationCenter()}
        {selectedItems?.label === "Detox Programs" && renderDetoxPrograms()}
      </Box>
    );
  };
  medicalOption=(option: { checked: boolean; label: string, value: string; },index: number)=>{
    return(
      <Box>
         {option.checked && (
          <MenuItem >
           <Input
  key={option.label}
  sx={{
    ...webStyles.listItemName,width: "100%", 
    margin: "0 auto", overflow: "hidden", textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block",  borderBottom:"1px solid #CBD5E1",
  }}
  placeholder={"Enter " + option.label}
  disableUnderline
  inputProps={{
    style: {
      cursor: "text",
      overflow: "hidden",
      textOverflow: "ellipsis",whiteSpace: "nowrap", width: "100%", 
    },
  }}
  data-test-id="txtInputText1"
  value={option.value}
  onChange={(e) => this.handleTextChange(option.label, e.target.value)}
/>

          </MenuItem>
        )}
      
        </Box>
    
    )
  }
  supportOption=(option: { checked: boolean; label: string; value: string; },index: number)=>{
    return(
      <Box>
         {option.checked && (
                <MenuItem >
                  <Input 
                        key={option.label}
                              type={"text"}
                              disableUnderline
                              sx={{
                                ...webStyles.listItemName,
                                width: "100%", // Ensures proper alignment
                                margin: "0 auto", // Centers the input
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                
                                display: "block",
                                borderBottom:"1px solid #CBD5E1",
                              }}
                            placeholder={"Enter "+option.label}
                            inputProps={{
                              style: {
                                cursor: "text",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                width: "100%", 
                              },
                            }}
                              data-test-id="txtInputText"
                    value={option.value}
                    onChange={(e) => this.handleTextChangeSupport(option.label, e.target.value)}
                  />
                </MenuItem>
                
              )}
      </Box>
    )
  }
  getBorderStyleWith = (index: number, length: number) => {
    
    return index === length - 1? 'none' : '1px solid #CBD5E1';
    
  };
  getBorderStyle = (index: number, length: number,checked:boolean) => {
    console.log(checked,index , length - 1)
    if(!checked){
    return index === length - 1? 'none' : '1px solid #CBD5E1';
    }
  };
  reservedBed=()=>{
    return(
      <span>
      {this.state.reserveBedId && this.state.refOrgDetails.organization?.attributes?.organization_name?
        <FormControl fullWidth variant="outlined"
          sx={{ backgroundColor: '#ffffff',marginY:1,padding:"10px 0px 10px 0px",gap:"8px",
            borderRadius: '8px',
            border: "1px solid #CBD5E1",}}>
                <Typography sx={{fontWeight: 700,
                fontSize: "14px",
                lineHeight: "22px",
                letterSpacing: "0%",marginLeft:2,marginTop:"5px"
                }}>Referral Information</Typography>
                <Typography sx={{fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                letterSpacing: "0%",color:"#059669",marginLeft:2
                }}>Referrral made</Typography>
                 <Typography sx={{fontWeight: 400,
                fontSize: "14px",
                lineHeight: "22px",
                letterSpacing: "0%",color:"#565047",marginLeft:2
                }}>Organization</Typography>
        <Typography sx={{fontWeight: 700,
                fontSize: "14px",
                lineHeight: "22px",
                letterSpacing: "0%",color:"#565047",marginLeft:2
                }}>{this.state.selectedRefOrg.organization_name}</Typography>
        </FormControl>
        :null}    
        </span>  
    )
  }
  bedAvaialbility=()=>{
    const isLoading = this.state.refOrgDetails.is_bed_availble === undefined;
return(
  <span>
     {isLoading ? (
    <Typography sx={{ marginLeft: 3, fontWeight: "700", fontSize: "16px", font: "Inter", color: "#f59e0b" }}>
      Checking availability...
    </Typography>
  ) : (
    <Typography sx={{
      marginLeft: 3,
      fontWeight: "700",
      fontSize: "16px",
      font: "Inter",
      color: this.state.refOrgDetails.is_bed_availble ? "#059669" : "#DC2626"
    }}>
      {this.state.refOrgDetails.is_bed_availble ? "There are beds available" : "No beds available"}
    </Typography>
  )}
  </span>
)
  }
  displayPin=()=>{
    return(
      <span>
        {this.state.errorpin && this.state.selectedPin ? 
        <Typography sx={{color:"#DC2626",fontSize:"12px",marginTop:0.5}}>Please select the map location</Typography>:null}
          
      </span>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    // Customizable Area End
    return (
      // Customizable Area Start
      <NavigationMenu navigation={this.props.navigation} id={"HealthcareAccessForm"}>
      <TextContainer>
          <Button data-test-id="btnGoback" sx={webStyles.goBackButton} onClick={()=>this.goback("LandingPage")}>
            <KeyboardArrowLeftIcon sx={{color:"black"}}/>
            <Typography style={webStyles.goBackText} >Go Back</Typography>
          </Button>
          <Typography style={webStyles.comunityType}>Referrals and Warm Hand Offs</Typography>
         
          <Button data-test-id="btnmap" onClick={()=>this.setState({openMap:true})} fullWidth sx={webStyles.mapButton}>Map</Button>
          {this.displayPin()}
          <Box sx={{margin:0.5,borderRadius: "8px", }}>
      
                        <View data-test-id="View">
                       
                      
                     <Box>
                       <Typography sx={[{fontWeight:"700",fontSize:"16px",font:"Inter",marginTop:2}]}>Substance Use Care/ Harm Reduction Services</Typography>
                       <FormControl fullWidth variant="outlined"
          sx={webStyles.formControl}>
                       <Select 
            value={this.state.selectedItems?.label}
            displayEmpty
            style={webStyles.selectMenu}
            IconComponent={ExpandMoreIcon}
          data-test-id="SelectItemFisrt"
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: 5, 
                },
              },
              disableAutoFocusItem: true,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            renderValue={() => this.state.selectedItems.label||'Select'
            }
          >   
            {this.state.allServices?.map((option:any,index) => (
                  <span>
             
              <MenuItem key={option.label} value={option.label}
              style={{
                ...webStyles.checkBoxMenu,
                  borderBottom: this.getBorderStyleWith(index, this.state.allServices.length) 
               
              }}
            >
                <Box  sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={option.label} >
                  <Grid flexDirection={"row"} container sx={[webStyles.checkboxContainer,]}>
                    <Grid xs={2} item> <Checkbox
                    data-test-id="checkBoxIdMenuFirst"
                      edge="start"
                      checked={option.checked}
                      tabIndex={-1}
                      disableRipple
                      onClick={() => this.handleToggle(option)}  
                      sx={{
                        color: "#64748B",width:"10%",
                        borderRadius: "6px",
                        '&.Mui-checked': {
                          color: "#CF9500",
                        }
                      }}
                    /></Grid>
                    <Grid xs={10} item> <Typography sx={{ textAlign: "left"}} style={webStyles.listItemName}>{option?.label}</Typography></Grid>
                   
                   
                  </Grid>
                 
                    
                </Box>
              
               
              </MenuItem>
         {this.rehabOption(option)}
              
                </span>
            ))}
          </Select>
                   </FormControl>

               </Box> 
            
                        </View>
                       {this.reservedBed()}
                        </Box>

                        <Box sx={{margin:0.5,borderRadius: "8px", }}>
      <View data-test-id="View1">
      <Typography sx={[{fontWeight:"700",fontSize:"16px",font:"Inter",marginTop:2}]}>Medical Care</Typography>
      <FormControl fullWidth variant="outlined"
          sx={webStyles.formControl}>
                       <Select 
            value={this.state.selectedMedical.label}
            displayEmpty
            style={webStyles.selectMenu}
            IconComponent={ExpandMoreIcon}
          data-test-id="SelectSecond"
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: 5, 
                },
              },
              keepMounted:true,
              disableAutoFocusItem: true,
              disableEnforceFocus:true,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              
            }}
            renderValue={() => this.state.selectedMedical.label ||'Select'
            }
          > 
           {this.state.medicalCare?.map((option:any,index) => (
                
                <Box key={option.label}>
              <MenuItem key={option.label} value={option.label}
              style={{
                ...webStyles.checkBoxMenu,
                borderBottom:this.getBorderStyle(index, this.state.medicalCare.length,option.checked) ,
               
              }}
            >
                <Box  sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={option.label} >
                  <Grid container flexDirection={"row"} sx={[webStyles.checkboxContainer,]}>
                    <Grid item xs={2}> <Checkbox
                    data-test-id="checkBoxIdMenuSecond"
                      edge="start"
                      checked={option.checked}
                      tabIndex={-1}
                      disableRipple
                      onClick={() => this.handleTogglemedical(option)}  
                      sx={{
                        color: "#64748B",width:"10%",
                        borderRadius: "6px",
                        '&.Mui-checked': {
                          color: "#CF9500",
                        }
                      }}
                    /></Grid>
                    <Grid 
                    sx={{
    margin: '0 auto',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',}} 
    item xs={10}>
                    <Typography sx={{ textAlign: "left"}} style={webStyles.listItemName}>{option?.label}</Typography>
                    </Grid>
                   
                    
                  </Grid>
                  
                    
                </Box>
              
               
              </MenuItem>
              {this.medicalOption(option,index)}
             
              </Box>
              ))}
            </Select>
          </FormControl>
             
        </View>
        {this.state.selectedMedical?.value?
        <FormControl fullWidth variant="outlined"
          sx={[webStyles.formControl,{marginY:1}]}>
        <Typography sx={[webStyles.selectMenu,{padding:1}]}>{this.state.selectedMedical.value}</Typography>
        </FormControl>:null}
        </Box>
        <Box sx={{margin:0.5,borderRadius: "8px", }}>
      <View data-test-id="View">
      <Typography sx={[{fontWeight:"700",fontSize:"16px",font:"Inter",marginTop:2}]}>Mental Health / Psychological</Typography>
      <FormControl fullWidth variant="outlined"
          sx={webStyles.formControl}>
                       <Select 
            value={this.state.selectedMentalHealth.label}
            displayEmpty
            IconComponent={ExpandMoreIcon}
            style={webStyles.selectMenu}
          data-test-id="SelectThird"
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: 5, 
                },
              },
              disableAutoFocusItem: true,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            renderValue={() => this.state.selectedMentalHealth.label||'Select'
            }
          > 
           {this.state.mentalHealth?.map((option:any,index) => (
              <MenuItem key={option.label} value={option.label}
              style={{
                ...webStyles.checkBoxMenu, borderBottom: this.getBorderStyleWith(index, this.state.mentalHealth.length) ,
              }}
            >
                <Box  sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={option.label} >
                  <Grid container flexDirection={"row"} sx={[webStyles.checkboxContainer,]}>
                    <Grid item xs={2}>
                      <Checkbox
                    data-test-id="checkBoxIdMenuThird" edge="start"
                      checked={option.checked} tabIndex={-1}
                      disableRipple
                      onClick={() => this.handleTogglementalHealth(option)}  
                      sx={{
                        color: "#64748B",width:"10%",
                        borderRadius: "6px",
                        '&.Mui-checked': {
                          color: "#CF9500",
                        }
                      }}
                    /></Grid>
                    <Grid sx={{width: '80%', 
    margin: '0 auto',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',}} item xs={10}> <Typography sx={{ textAlign: "left"}} style={webStyles.listItemName}>{option?.label}</Typography></Grid>
                    
                   
                  </Grid>
                 
                    
                </Box>
              
               
              </MenuItem>))}
            </Select>
          </FormControl>

        </View>
        </Box>

        <Box sx={{margin:0.5,borderRadius: "8px", }}>
      <View data-test-id="View">
      <Typography sx={[{fontWeight:"700",fontSize:"16px",font:"Inter",marginTop:2}]}>Support</Typography>
      <FormControl fullWidth variant="outlined"
          sx={webStyles.formControl}>
                       <Select 
            value={this.state.selectedSupport.label}
            displayEmpty
            IconComponent={ExpandMoreIcon}
            style={webStyles.selectMenu}
          data-test-id="SelectFourth"
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: 5, 
                },
              },
              disableAutoFocusItem: true,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            renderValue={() => this.state.selectedSupport.label||'Select'
            }
          > 
           {this.state.support?.map((option:any,index) => (
                 <Box key={option.label}>
             
              <MenuItem key={option.label} value={option.label}
              style={{
                ...webStyles.checkBoxMenu,
                borderBottom:this.getBorderStyle(index, this.state.support.length,option.checked) , 
               
              }}
            >
                <Box  sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={option.label} >
                  <Grid container flexDirection={"row"} sx={[webStyles.checkboxContainer,]}>
                    <Grid item xs={1}>  <Checkbox
                    data-test-id="checkBoxIdMenuFourth"
                      edge="start"
                      checked={option.checked}
                      tabIndex={-1}
                      disableRipple
                      onClick={() => this.handleToggleSupport(option)}  
                      sx={{
                        color: "#64748B",width:"10%",
                        borderRadius: "6px",
                        '&.Mui-checked': {
                          color: "#CF9500",
                        }
                      }}
                    /></Grid>
                    <Grid item xs={11} sx={{width: '80%', 
    margin: '0 auto',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    whiteSpace: 'normal',}} >
                    <Typography sx={{ textAlign: "left"}} style={webStyles.listItemName}>{option?.label}</Typography>
                    </Grid>
                  
                   
                  </Grid>
                 
                    
                </Box>
              
               
              </MenuItem>
              {this.supportOption(option,index)}
             
              </Box>))}
            </Select>
          </FormControl>

        </View>
        {this.state.selectedSupport.value?
        <FormControl fullWidth variant="outlined"
          sx={[webStyles.formControl,{marginY:1}]}>
        <Typography sx={[webStyles.selectMenu,{padding:1}]}>{this.state.selectedSupport.value}</Typography>
        </FormControl>:null}
        </Box>
        <Box sx={{margin:0.5,}}>
          <Typography 
          style={webStyles.areaText}>
            Additional Items</Typography>
          <TextField  value={this.state.additionalItem}
            variant="outlined" data-test-id="addidtionItem"
            size="small" multiline onChange={(text)=>this.setState({additionalItem:text.target.value})}
            rows={3} placeholder="Add additional items" style={webStyles.subBoxinputField}
          />
          </Box>
        <Button 
        data-test-id="openModalTestId" 
        onClick={this.handleOpenHarm} 
        fullWidth 
        sx={webStyles.mapButton}>
          Add Picture
        </Button>
        <Button 
        data-test-id="submitBtn" onClick={()=>this.createWarmHandsOff()} 
        fullWidth sx={[webStyles.submitButton,{"&:hover":{backgroundColor:"#F5C502"}}]}>
          Submit
          </Button>

        <Dialog data-test-id="dialogOpen"
            open={this.state.open} 
            onClose={this.handleCloseHarm}
            fullWidth 
            maxWidth="sm" 
            aria-labelledby="add-picture-dialog"
          >
            <DialogTitle>
              <span style={webStyles.addPictureHeadingText}>Add Picture</span>
              <IconButton
                data-test-id="closeModalTestId" 
                aria-label="close"
                onClick={this.handleCloseHarm} 
                sx={[webStyles.addPictureIcon,{fill:"#2D2A24"}]}
              >
                <CloseIcon sx={{fill:"#2D2A24"}}/> </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {this.renderFileListHarm()}
              <label>
              {this.displayResult()}
                <Box
                  display="flex" 
                  alignItems="center"
                  sx={webStyles.uploadButtonBox1} 
                  style={{ cursor: 'pointer' }}
                >
                  <Avatar 
                  style={webStyles.photoCamera1}>
                    <CameraAltOutlinedIcon style={webStyles.cameraIcon} />
                  </Avatar>
                  <Typography 
                  variant="body1" 
                  style={webStyles.addPictureText}>
                    Add picture
                  </Typography>
                  
                </Box>
               
                <input
                  type="file" 
                  accept="image/*"
                  multiple 
                  hidden 
                  data-test-id="fileChangeImageUpload"
                  onChange={this.handleFileChangeHarm}
                /> 
                </label>

            </DialogContent>
            {this.state.selectedFiles.length > 0 && (
              <DialogActions> 
                <Button
                  variant="contained"  
                  color="primary" fullWidth
                  data-test-id="uploadTestId"  
                  onClick={this.uploadImagesHarm}  sx={webStyles.uploadButton}
                >Upload
                </Button> </DialogActions>
            )}
          </Dialog>
          <Dialog data-test-id="dialogImageView" 
            open={this.state.openView} onClose={()=>this.handleCloseHarmView}
            fullWidth maxWidth="sm" aria-labelledby="add-picture-view-dialogharm">
            <DialogTitle>
              {this.title()}
              
              <IconButton data-test-id="closeModalTestId1" aria-label="close"
                onClick={this.handleCloseHarmView} 
                sx={[webStyles.addPictureIcon,{fill:"#2D2A24"}]}
              >
                <CloseIcon sx={{fill:"#2D2A24"}} /> </IconButton>
            </DialogTitle>

            <DialogContent style={{alignContent:"center",alignItems:"center",textAlign:"center",}}
             dividers>
              { this.state.removeImg ? 
              <span><Box sx={{
                backgroundColor: '#FEF3C7', 
                 color: '#D97706',
                padding: '10px 10px',
                borderRadius: '4px',                      
                  display: 'flex',
                alignItems: 'center',                       
                 borderLeft: '4px solid #D97706',
                marginBottom: 3
              }}>
                <Typography variant="body2" textAlign={"left"} color="#D97706" sx={{ fontWeight: "700",margin:1 }}>Are you sure you want to remove the image? </Typography>
              </Box>
                <Grid sx={{alignItems:"center",textAlign:"center"}} container>
                <Grid item xs={6}>  <Button sx={[{paddingLeft:"28%",paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
              textTransform: 'none',  color: "black", 
              font: "Inter", fontSize: "16px", fontWeight: "700",  height: "44px"
              ,border:"2px red solid",'&:hover': {  backgroundColor: '#E2E8F0',
              },
            }]}
            onClick={this.handleCloseHarmView}

              data-test-id={"editcancelButton1"}>No 
              </Button></Grid>
                <Grid item xs={6}>
                  <Button sx={[{paddingLeft:"28%",
                   paddingRight:"28%",marginTop:1.5,marginBottom:1.5,
              textTransform: 'none', backgroundColor:"#F5C502", 
              color: "black", font: "Inter", fontSize: "16px",
               fontWeight: "700",height: "44px",
              '&:hover': {
                backgroundColor: '#E2E8F0',
              },
            }]}

            onClick={()=>this.handleRemoveFile(toInteger(this.state.removeImg))}

              data-test-id={"btnEditmodalButton1"}>Yes 
              </Button>
              
                </Grid>

              </Grid> 

              </span>

              :
              <img
              
              src={this.state.imageView}
              alt="Uploaded Preview"
              style={{ width: "200px", height: "auto", 
                borderRadius: "8px", alignSelf:"center",textAlign:"center"}}
            />
              }
           
             </DialogContent>
          </Dialog>
          <Dialog data-test-id="dialogReserveBed" 
            open={this.state.openReferral} 
            fullWidth maxWidth="sm" aria-labelledby="add-picture-view-dialogharm">
            <DialogTitle sx={{backgroundColor:"#F6F6F3"}}>
             Referral
              <IconButton data-test-id="closeModalTestId12" aria-label="close"
                onClick={()=>this.setState({openReferral:false})} 
                sx={[webStyles.addPictureIcon,{fill:"#2D2A24"}]}
              >
                <CloseIcon sx={{fill:"#2D2A24"}}/> </IconButton>
            </DialogTitle>

            <DialogContent sx={{ textAlign: 'center', paddingY: 4 ,backgroundColor:"#F6F6F3"}}
             >
               <Box sx={{backgroundColor:"#F6F6F3",borderRadius: "8px", textAlign:"left"}}>
               <FormControl fullWidth variant="outlined"
          sx={webStyles.formControl}>
          <Select
            value={this.state.selectedRefOrg.organization_name}
            displayEmpty
            IconComponent={ExpandMoreIcon}
          data-test-id="SelectItem"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            renderValue={()=>this.state.selectedRefOrg?.organization_name?this.state.selectedRefOrg.organization_name:"Select an Organization"}
            
            sx={webStyles.selectMenu}
          >
            {this.state.referralorg?.map((item, index) => (
                <MenuItem
                key={item.organization_name}
                >
           <Typography onClick={(text:any)=>this.handleSelectChange(item)} data-test-id="SelectItemClick"  sx={{padding:1}} textAlign={"left"}>{item.organization_name}</Typography>
          </MenuItem>
            ))}
          </Select>
        </FormControl>
        {this.state.selectedRefOrg.organization_name?
       <span>
                         <Box sx={{backgroundColor:"white",borderRadius: "8px", textAlign:"left"}}>
                            <Grid sx={{backgroundColor:'',margin:0.5,borderRadius: "8px", paddingY:2}} >
                        
                                                    <Typography sx={{fontWeight:"700",fontSize:"16px",font:"Inter",color:"#2D2A24",marginLeft:1}}>
                                                       Information
                                                      </Typography>
                                                <Typography sx={[webStyles.selectedTitle,{}]}>Organization
                                                    </Typography>
                                                <Typography 
                                                sx={[webStyles.selectedValue,{fontWeight:"700",fontSize:"16px",
                                                    font:"Inter"}]}>{this.state.refOrgDetails.organization?.attributes.organization_name  }</Typography>
                                                <Typography 
                                                sx={[webStyles.selectedTitle,
                        
                                                    {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                                                    Contact Person </Typography>
                                                <Typography 
                                                sx={[webStyles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.refOrgDetails.organization?.attributes.contact_person}</Typography>
                                                <Typography sx={[webStyles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                                                    Contact Phone
                                                    </Typography>
                                                <Typography 
                                                sx={[webStyles.selectedValue,{}]}>{this.state.refOrgDetails.organization?.attributes.organization_phone}</Typography>
                                                <Typography 
                                                sx={[webStyles.selectedTitle,
                                                    {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Address</Typography>
                        
                                                <Typography sx={[webStyles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>
                        
                                                {this.state.refOrgDetails.organization?.attributes.address}
                                                </Typography>
                                               </Grid> 
                                                
                                              </Box>
                                              <Divider sx={{ my: 2,height:"2px", marginX:2}} />
                                           
                                              
                                                
                                                
                                                
                                              
                       <Box sx={{backgroundColor:"white",margin:0.5,padding:1,borderRadius: "8px", textAlign:"left"}}>
                       <Typography sx={[webStyles.selectedTitle,
                            {fontWeight:"700",fontSize:"16px",font:"Inter"}]}>
                          Bed Information
                            </Typography>
                            <Typography sx={[
                            {fontWeight:"400",fontSize:"16px",font:"Inter"}]}>
                         Bed Available
                            </Typography>
                            {this.bedAvaialbility()}
                           
                          
                      </Box></span>:null}
                      
                      </Box>
                      <Button sx={[{marginTop:1.5,marginBottom:1.5,marginY:2,
                                              textTransform: 'none', backgroundColor:this.state.refOrgDetails.is_bed_availble?"#F5C502":"#E2E8F0", 
                                              color: "black", font: "Inter", fontSize: "16px", fontWeight: "700",height: "44px",
                                              '&:hover': {
                                                backgroundColor: '#E2E8F0',
                                              },
                                            }]}
                                            fullWidth
                                            disabled={!this.state.refOrgDetails.is_bed_availble}
                                            onClick={()=>this.reserveBed()}
                                              data-test-id={"btnEditmodalButton"}>Reserve Bed 
                                              </Button>
             </DialogContent>
          </Dialog>
           <Dialog data-test-id="dialogMapView" 
                   sx={{"& .MuiPaper-root": {width: 'calc(100% - 10px)',margin:"10px",borderRadius: "12px", }}}   open={this.state.openMap} onClose={()=>this.setState({openMap:false})}
                      fullWidth maxWidth="sm" aria-labelledby="add-picture-view-dialogharm">
                      <DialogTitle sx={{padding: "3px 15px"}}>
                        <span style={webStyles.addPictureHeadingText}>Map</span>
                        <IconButton data-test-id="closeModalmap" aria-label="close"
                          onClick={()=>this.setState({openMap:false})} 
                          sx={[webStyles.addPictureIcon,{}]}
                        >
                          <CloseIcon sx={{fill:"#2D2A24", height:"20px",width:"20px",top: "3.51px"}}/> </IconButton>
                      </DialogTitle>
          
                      <DialogContent 
                       sx={{padding: "20px 10px"}}>
                      <Location sendDataToPage1={this.handleDataFromPage} data={"health_care_access"} navigation={this.props.navigation} id={""} />
                       </DialogContent>
                    </Dialog>
                    {this.renderModal()}
        </TextContainer>
        </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles = {
  selectedTitle:{marginLeft:1,margin:1,color:"#2D2A24",fontWeight:"400",fontSize:"16px",font:"Inter"},
  selectedValue:{marginLeft:2,marginBottom:1,color:"#565047",fontWeight:"700",fontSize:"16px",font:"Inter"},
  modalHeading: {
    width:"80%",
    fontWeight: 700,
    color:"#2D2A24",
    fontSize:"24px", 
  },arrowIcon: {
    fontSize:"14px",
    fontWeight:400,
    color:"#2D2A24"
  },
  dropUI:{
    position: "absolute",
    top: "100%",
    right: 0,
    background: "#fff",
    border: "1px solid #ccc",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    zIndex: 1000,
  },
  dropContainer:{
    padding: "8px 16px",
    display: "flex",
    alignItems: "center",
    font:"Inter",
    gap: "8px",
  },
  modal:{
    position: 'absolute',
  top: '10%',
  margin:2,
  backgroundColor:"#F6F6F3",
  
  padding:2,
  
  },
  subBoxinputField: {
    marginBottom:"12px",
    marginLeft:1,
    fontSize: "16px",
    width:"100%",
    // color: "#94A3B8",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    backgroundColor: "white",
    fontWeight: 400,
    '& .MuiOutlinedInputRoot': {
      borderRadius: "8px",
      '& fieldset': {
        borderColor: "transparent",
      },
      '&.Mui-focused fieldset': {
        borderColor: "transparent",
      },
      '&:hover fieldset': {
        borderColor: "transparent",
      },
    }
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  goBackButton: {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'none',
    gap:"2px",
  },
  goBackText: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#1D4ED8',
    lineHeight: '22px',
  },
  comunityType: {
    color:"#2D2A24",
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing:"-0.05%",
    marginTop:"16px"
  },
  areaText: {
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    margin:"32px 0 8px 0"
  },
  inputDefaultLabel: {
    color:"#94A3B8",
    fontSize:"16px",
    fontWeight:400
  },
  formControl: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    height:"44px",
  },
  menuItems: {
    backgroundColor: '#ffffff',
    borderBottom: "1px solid #CBD5E1",
    color:"#2D2A24",
    fontSize:"14px",
    fontWeight:700,
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  menuItemsSecond: {
    fontWeight:700,
    backgroundColor: '#ffffff',
    fontSize:"14px",
    color:"#2D2A24",
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  mapButton: {
    height:"44px",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    textTransform:"capitalize",
    borderRadious:"8px",
    marginTop:"32px",
  },
  submitButton: {
    marginTop:"24px",
    justifyContent:"center",
    height:"44px",
    textTransform:"capitalize",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    color:"#2D2A24",
    fontSize: '16px',
    borderRadious:"8px",
    fontWeight: 700,
  },
  referralButton: {
    marginTop:"14px",
    justifyContent:"center",
    height:"44px",
    textTransform:"capitalize",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    color:"#2D2A24",
    fontSize: '16px',
    borderRadious:"8px",
    fontWeight: 700,
    marginLeft:2,
    marginRight:2,
  },
  listItemName: {
    fontWeight:700,
    fontSize:"14px",
    color:"#2D2A24",
  },
  listItemQuantity: {
    fontWeight:400,
    fontSize:"16px",
    color:"#2D2A24",
  },
  itemsMainBox: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%",
  },
  checkboxContainer: {
    justifyContent:"space-between",
    display:"flex",
    alignItems:"center",
  },
  selectMenu: {
    height: "44px",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
  },
  estimatedItems: {
    color:"#2D2A24",
    fontWeight:400,
    fontSize:"16px",
  },
  estimatedBox: {
    height:"44px",
    justifyContent:"space-between",
    alignItems:"center",
    display:"flex",
    marginTop:"8px",
    padding:"0px 32px"
  },
  totalItems: {
    fontWeight:700,
    fontSize:"16px",
    color:"#2D2A24",
  },
  uploadButtonBox: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  photoCamera: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  cameraIcon: {
    color: "#2D2A24",
    fontSize: "24px"
  },
  addPictureText: {
    marginLeft: '16px',
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:400
  },
  pendingImageBox: {
    height: "48px",
    width: "48px",
    borderRadius: "6px",
    backgroundColor: '#FFFDC1'
  },
  defaultImageIcon:{
    color:"#F5C502",
    fontSize: "20px"
  },
  uploadButton: {
    backgroundColor: '#F5C502',
    height:"44px",
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:700,
    textTransform:"capitalize",
  },
  checkBoxMenu: {
    height: "44px",
  },
  serviceSelect: {
    height:"44px",
    fontSize:"16px",
    fontWeight:700,
  },
  umbrellaImageBox: {
    backgroundColor: "white",
    display: 'flex',
    width: "100%"
  },
  umbrellaImage: {
    width: "375px",
    height: "240px",
    padding: "20px",
    alignSelf: "center"
  },
  addPictureHeadingText: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#2D2A24"
  },
  tableContainerBox: {
    boxShadow: 'none',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
  },
  addPictureIcon: {
    position: 'absolute',
    right: "4.51px",
    top: "4.51px"
  },
  footerGridBox: {
    height: "auto",
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    justifyContent: "center",
    alignItems: "center",
    textAlignVertical: "center",
    alignContent: "center"
  },
  photoCamera1: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  footerLogo: {
    width: "190.02px",
    height: "26.47px",
    backgroundColor: "#F5C502",
    borderRadius: 20,
    marginLeft: 10, 
    margin: 5,
    paddingLeft: 1, 
    marginBottom: 5
  },
  uploadButtonBox1: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  footerView: {
    height: 1, 
    backgroundColor: "#F5C502", 
    paddingHorizontal: 20
  }
};
// Customizable Area End
