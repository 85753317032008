import React from "react";


// Customizable Area Start

import { View, StyleSheet } from "react-native";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { brush, editOrg, profile, search } from "./assets";
import {
  Grid,Typography,Modal,Box,Input,InputAdornment, IconButton,Button, MenuItem, FormControl, OutlinedInput, Select, FormHelperText,
  Checkbox,Dialog, 
  DialogTitle, 
  DialogContent, 
} from "@mui/material";
import { deviceHeight } from "../../../framework/src/Utilities";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {  referrals, settings } from "../../landingpage/src/assets";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
// Customizable Area End

import Rolesandpermissions2Controller, {
  Props,
  configJSON,
} from "./Rolesandpermissions2Controller";

export default class Rolesandpermissions2 extends Rolesandpermissions2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderModal = () => (
    <Dialog data-test-id={"btnModalClose"}
      open={this.state.successModal}
      onClose={()=>this.setState({successModal:false})}
      maxWidth="xs"
      fullWidth sx={{"& .MuiPaper-root": {width: 'calc(100% - 40px)',margin:"10px",borderRadius: "12px", }}}
    >
      <DialogTitle>
        <Typography sx={webStyles.modalHeading}>
        {this.state.title}
        </Typography>
        <IconButton data-test-id={"btnModal1"}
          onClick={()=>this.setState({successModal:false})}
          sx={{ position: 'absolute', right: 8, top: 2 }}
        >
          <CloseIcon sx={{fill:"#2D2A24",height:"20px",width:"20px"}} />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ textAlign: 'center', paddingY: 4 }}>
      {this.state.success? <CheckCircleOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "green",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> 
          :<ErrorOutlineIcon  sx={{fontSize:"64px",paddingLeft:"40%",paddingRight:"40%",paddingTop:1,paddingBottom:1}} style={{ fill: "red",marginRight:10,alignContent:"center",alignItems:"center",alignSelf:"center"}} /> }
          <Typography fontWeight={400} sx={{fontSize:"14px",lineHeight:"22px",textAlign:"center",marginTop:3}}>{this.state.subTitle}</Typography>
      </DialogContent>
    </Dialog>
  )
  renderEditModal = () =>{
    return(
    <Dialog data-test-id={"btnModalClose2"}
    open={this.state.open}
    onClose={()=>this.handleClose()}
    maxWidth="xs" sx={{"& .MuiPaper-root": {width: 'calc(100% - 40px)',margin:"10px",borderRadius: "12px", }}}
    fullWidth
  >
    <DialogTitle sx={{backgroundColor:"#F6F6F3"}}>
      <Typography sx={webStyles.modalHeading}>
      {this.state.title}
      </Typography>
      <IconButton data-test-id={"btnModal2"}
        onClick={()=>this.setState({open:false})}
        sx={{ position: 'absolute', right: 8, top: 2 }}
      >
        <CloseIcon sx={{fill:"#2D2A24",height:"20px",width:"20px"}}/>
      </IconButton>
    </DialogTitle>

    <DialogContent sx={{ textAlign: 'left', paddingY: 4 ,backgroundColor:"#F6F6F3"}}>
    <Box >
         
        {/* <CloseIcon data-test-id="btnModalIcon" sx={{height:"20px",marginLeft:"93%"}} onClick={()=>this.handleClose()} /> */}
       
        <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px"}}>Profile Manager</Typography>
        <Typography fontWeight={700} sx={{fontSize:"18px",lineHeight:"26px",marginTop:1,marginBottom:1}}>{this.state.orgnization.organization_name}</Typography>
        <Typography fontWeight={400} sx={{fontSize:"16px",lineHeight:"26px",marginTop:1.5,marginBottom:0.5}}>Add/Edit Profile</Typography> 
        <Grid container sx={{ display: "flex"}} direction={"row"} flexDirection={"row"}>
          <Grid item xs={9} >  
            <View style={styles.input}>
        <Input
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px",  
                        paddingTop:0.5
                        }}
                        disableUnderline
                        data-test-id="txtInputSearch"
                        type={ 'text'}
                        placeholder={"Profile Search"}
                        onChange={(text:React.ChangeEvent<HTMLInputElement>)=>this.searchFilterFunction(text.target.value)}
                        value={this.state.searchtext}
                        startAdornment={
                          <InputAdornment sx={{marginBottom:0.5}} position="end">
                           
                              <img style={{height:"21.01px",marginRight:8}} src={search.default} />
                          
                          </InputAdornment>
                        }
                      />
                        <img data-test-id="imageClick" onClick={()=>this.clear()} style={{height:"21.01px",marginRight:5}} src={brush.default} />
                      </View>
          </Grid>
          <Grid item xs={2} sx={{paddingLeft:1}}>
           <Button onClick={()=>this.addClicked()} data-test-id={"btnAdd"} sx={[{backgroundColor:"#F5C502",width:"40px",height:"44px",color:"black", textTransform: 'none',fontWeight:"700",fontSize:"16px",'&:hover': {
                       color:"#64748B", fontWeight:"700",fontSize:"16px"
                      },}]}>Add</Button>
          </Grid>
      
                      </Grid>
                      {this.noResult()}
                     {this.state.searchResult.length > 0 && <Box sx={{backgroundColor:"white",marginY:1,padding:1,borderRadius: "8px", }}>
                      {this.state.searchResult.map((item:{first_name:string,last_name:string,id:number,status:string,phone_number:string,email:string}, index) => (
                      
                       <Grid data-test-id={"btnSelect"} sx={{backgroundColor:this.state.selectedResult.id===item.id ? "#FEF3C7":'',margin:0.5,padding:1,borderRadius: "8px", }} onClick={()=>this.selectResult(item)}>
                           <Typography sx={{marginLeft:1,fontWeight:400,fontSize:"16px"}}>{item.first_name} {item.last_name}</Typography>
                        </Grid>
                       
                    ))}
                        </Box>}
                     
                     
                      <View style={{backgroundColor:"#D9D9D9",height:"2px",marginVertical:5}} />
                      {this.state.selectedResult.id>0 && 
                      <Box sx={{backgroundColor:"white",margin:0.5,borderRadius: "8px",marginY:1, gap:"8px" }}>
                        <Grid sx={{backgroundColor:'',margin:0.5,padding:1,borderRadius: "8px", }} >
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>First Name</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter",padding:"10px 8px 10px 8px"}]}>{this.state.selectedResult.first_name}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Last Name</Typography>
                        <Typography sx={[styles.selectedValue,{padding:"10px 8px 10px 8px",fontWeight:"700",fontSize:"16px",font:"Inter",marginLeft:2}]}>{this.state.selectedResult.last_name}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Status</Typography>
                        <Grid sx={{padding:"10px 8px 10px 8px",}} container>
                          <Grid item xs={1}>
                          {this.state.selectedResult.status ==="Active" ? 
                        <CheckCircleOutlineIcon sx={{fontSize:"24px"}} style={{ fill: "green",marginLeft:15}} /> :
                        <HighlightOffIcon sx={{fontSize:"24px"}} style={{ fill: "red",marginLeft:15 }} />}
                          </Grid>
                          <Grid item xs={3} sx={{alignContent:"flex-start",alignItems:"flex-start"}}>
                          <Typography style={{marginRight:10}} sx={[styles.selectedValue,{marginLeft:3,fontWeight:"700",fontSize:"16px",font:"Inter",textAlign:"left"}]}>
                          {this.state.selectedResult.status}</Typography>
                          </Grid>
                        
                       
                        </Grid>
                        
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Phone Number</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",font:"Inter",padding:"10px 8px 10px 8px"}]}>{this.state.selectedResult.phone_number}</Typography>
                        <Typography sx={[styles.selectedTitle,{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Email Address</Typography>
                        <Typography sx={[styles.selectedValue,{padding:"10px 8px 10px 8px",fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{this.state.selectedResult.email}</Typography>
                        </Grid> 
                      </Box>
                      }

                      <Button sx={[{paddingLeft:"45%",paddingRight:"45%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none', backgroundColor:this.state.selectedResult.id>0? "#F5C502":"#E2E8F0", color: this.state.selectedResult.id>0?"black":"#64748B", font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}
                      data-test-id={"btnEditButton"}
                      disabled={!this.state.selectedResult.id}
                      onClick={()=>this.editClicked()}
                    >Edit</Button>
        </Box>
    </DialogContent>
  </Dialog>
    )
  }
  showError=()=>{
   return this.state.error && <FormHelperText sx={{ marginLeft: 1 }}>{this.state.errorMessage}</FormHelperText>
   
  }
  showErrorF=()=>{
    return this.state.errorFname && <FormHelperText sx={{ marginLeft: 1 }}>{this.state.errorMessageF}</FormHelperText>
    
   }
   showErrorL=()=>{
    return this.state.errorlname && <FormHelperText sx={{ marginLeft: 1 }}>{this.state.errorMessageL}</FormHelperText>
    
   }
  showEmailError=()=>{
    return this.state.errorEmail && <FormHelperText sx={{ marginLeft: 1 }}>Please enter valid email address.</FormHelperText>
  }
  showErrorpass=()=>{
    return this.state.errorPass && <FormHelperText sx={{ marginLeft: 1 }}>{this.state.errorMessagepass}</FormHelperText>
  }
  noResult=()=>{
    return this.state.enableAdd && this.state.searchtext.length>0 ? <Box sx={{backgroundColor:"white",margin:0.5,padding:1,borderRadius: "8px", }}> <Typography>No search results found</Typography></Box>:null
  }
  showRender=()=>{
   return this.state.selectedResult.status ? this.status() : "Select a status"
  }
  showEditForm(){
   return this.state.operationType==="EditForm" &&
      <Grid item xs={8} style={{ padding: 5 }}>
        <Button data-test-id="goBackButton2E" sx={{ alignItems: 'center',paddingTop:2,textTransform: 'none', gap:"8px",marginLeft:"10px"}} onClick={()=>this.gobackIn()}>
                  <KeyboardArrowLeftIcon style={{color:"black"}}/>
                  <Typography fontWeight={400} sx={{fontSize:"14px",color:"#1D4ED8",}}>Go Back</Typography>
                  </Button>
        <View style={styles.edit1}>
        
          <View style={{
            flex: 1,
            padding: "12px"
          }}>
           

                 

                  
           
  
    <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px"}}>Edit Outreach Worker</Typography>
    <Typography fontWeight={700} sx={{fontSize:"18px",lineHeight:"26px",marginTop:1,marginBottom:1}}>{this.state.orgnization.organization_name}</Typography>
 

          </View>
          <View style={{
            display: "flex",
            paddingTop: "20px", padding: 10
          }}>
          <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                First Name
              </Typography>
                <Input
                  type="text"
                  disableUnderline
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  data-test-id="txtInputfirst"
                  // placeholder={"First Name"}
                  value={this.state.selectedResult.first_name}
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      first_name: e.target.value,  
                    },
                  }))}
                 
                />

            <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Last Name
              </Typography>
                <Input
                  type="text"
                  disableUnderline
                  value={this.state.selectedResult.last_name}
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      last_name: e.target.value,  
                    },
                  }))}
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  data-test-id="txtInputlast"
                 />
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Status
              </Typography>

                
             
              <FormControl variant="outlined" fullWidth>
              <Select 
              input={<OutlinedInput notched={false} />}
                       displayEmpty
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px",  height: "44px",
                     borderColor:  "#ccc",marginTop:1,marginBottom:3,justifyContent:"center"
                  }}
                  MenuProps={{
                    disableScrollLock: true, 
                  }}
                  IconComponent={ExpandMoreIcon}
                  renderValue={()=> this.showRender() }
                  data-testid="txtInputStatus"
                  label="Select a status"
                  value={this.state.selectedResult.status}
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      status: e.target.value,  
                    },
                  }))}
                >
                 {/* <MenuItem  value="" disabled>
                   Select a status
                    </MenuItem> */}
                        <MenuItem sx={{borderBottom:'1px solid #CBD5E1'}} value={'Active'}><Box sx={{  display: "flex", alignItems: "center", gap: 1,fontSize:"14px",fontWeight:700 }}><CheckCircleOutlineIcon sx={{fontSize:"16px"}} style={{ fill: "green",marginRight:10}} />  Active </Box> </MenuItem>
                        <MenuItem value={'Inactive'}>
                        <Box sx={{  display: "flex", alignItems: "center", gap: 1,fontSize:"14px",fontWeight:700}}><HighlightOffIcon sx={{fontSize:"16px"}} style={{ fill: "red",marginRight:10 }} />Inactive</Box> </MenuItem>
                      </Select>
          </FormControl>
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Phone Number
              </Typography>
                <Input
                  type="text"
                  disableUnderline
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,marginBottom:3
                  }}
                  data-test-id="txtInputPhone"
                  value={this.state.selectedResult.phone_number}
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      phone_number: e.target.value,  
                    },
                  }))}
                />
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Email Address
              </Typography>
              <FormControl sx={{marginBottom:3}} error={this.state.errorEmail} fullWidth>
                <Input
                  type="email"
                  disableUnderline
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,
                  }}
                  data-test-id="txtInputEmail1"
                  value={this.state.selectedResult.email}
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      email: e.target.value,  
                    },errorEmail:false
                  }))}
                  onBlur={() => this.handleBlur()}
               />
                {this.showEmailError()}
                </FormControl>

           

          <Button sx={[{
                textTransform: 'none', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px", fontWeight: "700",marginY:2,
                height: "44px",
                '&:hover': {
                  backgroundColor: '#FFC300',
                },
              }]}
                data-test-id={"btnSaveEdit"}
                onClick={()=>this.editWorker()}
              >Save</Button>
             </View>
         
        </View>
      </Grid>
  }
  status=()=>{
    return(
      <Box sx={{ display: "flex", alignItems: "center", fontSize:"16px", fontWeight:700,
        height: 44,
        gap: "8px",
        borderRadius: "8px",
        borderWidth: "1px",
        paddingTop: "10px",
        paddingRight: "8px",
        paddingBottom: "10px",
        paddingLeft: "8px", 
         }}> {this.state.selectedResult.status ==="Active" ?<CheckCircleOutlineIcon sx={{fontSize:"24px"}} style={{ fill: "green",marginRight:10,}} />: <HighlightOffIcon sx={{fontSize:"24px"}} style={{ fill: "red",marginRight:10 }} />} 
      {this.state.selectedResult.status}</Box>
                        // <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}><HighlightOffIcon sx={{fontSize:"24px"}} style={{ fill: "red",marginRight:10 }} />Inactive</Box>
                     
    )
  }
  showAddForm(){
    return  this.state.operationType==="AddForm" && 
      <Grid item xs={8} style={{ padding: 5 }}>
          <Button data-test-id="goBackButton2" sx={{alignItems: 'center',paddingTop:2,textTransform: 'none', gap:"8px",marginLeft:"10px"}} onClick={()=>this.gobackIn()}>
                  <KeyboardArrowLeftIcon style={{color:"black"}}/>
                  <Typography fontWeight={400} sx={{fontSize:"14px",color:"#1D4ED8",}}>Go Back</Typography>
                  </Button>
        <View style={{
          display: "flex",
          paddingHorizontal: 10
        }}>
         
          <View style={{
            flex: 1,
            padding: "12px"
          }}>
            
           
    
    <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px"}}>Add Outreach Worker</Typography>
    <Typography fontWeight={700} sx={[{fontSize:"18px",lineHeight:"26px",}, styles.subText]}>{this.state.orgnization.organization_name}</Typography>
 

          </View>
          <View style={[{paddingTop: "20px", },styles.addui]}>
          <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                First Name
              </Typography>
              <FormControl sx={{marginBottom:3}} error={this.state.errorFname} fullWidth>
                <Input type="text"  disableUnderline
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,
                  }}
                  data-test-id="txtInputnameAdd"
                  
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      first_name: e.target.value,  
                    },errorFname: false,
                  }))}
                  onBlur={() => this.handleFirstName()}
                />
                 {this.showErrorF()}
                 </FormControl>

            <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Last Name
              </Typography>
              <FormControl sx={{marginBottom:3}} error={this.state.errorlname} fullWidth>
                <Input type="text"
                  disableUnderline
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,
                  }}
                  data-test-id="lastNameAdd"
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      last_name: e.target.value,  
                    },errorlname:false
                  }))}
                  onBlur={() => this.handleLastName()}
                 />
                  {this.showErrorL()}
                 </FormControl>
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Status
              </Typography>
              <FormControl variant="outlined" fullWidth>
              <Select 
               value={this.state.selectedResult.status}
                 MenuProps={{
                    disableScrollLock: true, 
                  }}
              onChange={(e) => this.setState((prevState) => ({
                selectedResult: {
                  ...prevState.selectedResult, 
                  status: e.target.value,  
                }
                
              }))}
              renderValue={()=>this.state.selectedResult.status ? this.status():"Select a status"}
              input={<OutlinedInput notched={false} />}
                       displayEmpty
                  sx={{
                    backgroundColor:this.state.selectedResult.status ? "#FFFDE7":"white", borderWidth: 1, borderRadius: "8px",paddingBottom: '10px', paddingTop: '10px', height: "44px",
                     borderColor:  "#CBD5E1",marginTop:1,marginBottom:3, "& .MuiSelect-select": {
                      color: this.state.selectedResult.status ? "inherit" : "#CBD5E1",  borderColor:  "#CBD5E1"// Text color based on value
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: "1px solid #CBD5E1",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#CBD5E1",
                    },
                  }}
                  IconComponent={ExpandMoreIcon}
                  data-test-id="txtSelectAdd"
                label="Select a status"
                >
                   {/* <MenuItem  value="" disabled>
                   Select a status
                    </MenuItem> */}
                    <MenuItem sx={{borderBottom:'1px solid #CBD5E1'}} value={'Active'}><Box sx={{ display: "flex", alignItems: "center", gap: 1,fontSize:"14px",fontWeight:700 }}> <CheckCircleOutlineIcon sx={{fontSize:"16px"}} style={{ fill: "green",marginRight:10,}} />  Active</Box></MenuItem>
                        <MenuItem value={'Inactive'}> <Box sx={{ display: "flex", alignItems: "center", gap: 1,fontSize:"14px",fontWeight:700 }}><HighlightOffIcon sx={{fontSize:"16px"}} style={{ fill: "red",marginRight:10 }} />Inactive</Box></MenuItem>
                     
                      </Select>
          </FormControl>
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Phone Number
              </Typography>
              <FormControl sx={{marginBottom:3}} error={this.state.error} fullWidth>
                <Input 
                  type="phone"
                  inputProps={{ maxLength: 10 }}
                  disableUnderline
                  onChange={this.handleChangePhone}
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1,
                  }}
                  data-test-id="txtInputPhoneAdd"
                  onBlur={() => this.handlePhone()}
                />
               {this.showError()}
                </FormControl>
                
                <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
                Email Address
              </Typography>
              <FormControl sx={{marginBottom:3}} error={this.state.errorEmail} fullWidth>
                <Input
                  type="email"
                  disableUnderline
                  onChange={(e) => this.setState((prevState) => ({
                    selectedResult: {
                      ...prevState.selectedResult, 
                      email: e.target.value,  
                    },errorEmail:false
                  }))}
                  sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid ', borderColor:  "#ccc",marginTop:1
                  }}
                  data-test-id="txtInputEmailAdd"
                 
                  onBlur={() => this.handleBlur()}
                />
                {this.showEmailError()}
                </FormControl>

              <Typography component="h1" sx={{fontWeight:700,fontSize:"16px",font:"Inter"}} style={styles.textFont}>
              Temporary Password
              </Typography>
              <FormControl sx={{marginBottom:3}} error={this.state.errorPass} fullWidth>
                <Input sx={{
                    backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                    border: '1px solid',borderColor:  "#ccc",marginTop:1
                  }}
                  disableUnderline
                  data-test-id="txtInputPassword"
                  type={this.state.enablePasswordField ? 'password' : 'text'}
                  placeholder={"Create a Password"}
                  value={this.state.password}
                  onChange={this.setPassword}
                  onBlur={() => this.handleBlurPass()}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        data-test-id="togglePasswordButton"
                        onClick={this.handleClickShowPassword}
                        edge="end"
                      >
                        {this.state.enablePasswordField ? <VisibilityOffOutlinedIcon style={{ fill: "grey" }} /> : <VisibilityOutlinedIcon style={{ fill: "grey" }} />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {this.showErrorpass()}
                </FormControl>

          <Button sx={[{
                marginY:2,textTransform: 'none', backgroundColor: "#FFC300", color: "#2D2A24", font: "Inter", fontSize: "16px", fontWeight: "700",
                height: "44px",
                '&:hover': {
                  backgroundColor: '#FFC300',
                },
              }]}
                data-test-id={"btnSaveAdd"}
                onClick={()=>this.addWorker()}
              >Save</Button>
             </View>
         
        </View>
      </Grid>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <NavigationMenu navigation={this.props.navigation} id={""}>
      <Grid container sx={{ flexDirection: { md: "row-reverse" }, backgroundColor: "#f3f3f1" }}>
      
      <Grid item xs={12} md={8}>
        <View style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
          <Grid container direction="column"  >
         
            {this.state.operationType==="mainForm" ? 
            <Grid item xs={8} style={{ padding: 5 }}>
 <Button data-test-id="goBackButton" sx={{ textTransform: 'none',paddingTop:2,gap:"8px",marginLeft:"10px" }} onClick={()=>this.goback("LandingPage")}>
                  <KeyboardArrowLeftIcon style={{color:"black"}}/>
                  <Typography fontWeight={400} sx={{fontSize:"14px",color:"#1D4ED8",}}>Go Back</Typography>
                  </Button>
              <View style={{
                display: "flex",
                paddingHorizontal: 10,
                paddingBottom:10
              }}>
               
                <View style={{
                  flex: 1,
                  padding: "12px"
                }}>
                 
                 
          
          <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px"}}>Maintenance</Typography>
          <Typography fontWeight={700} sx={{fontSize:"18px",lineHeight:"26px",marginTop:1,marginBottom:1}}>{this.state.orgnization.organization_name}</Typography>
         <Typography fontWeight={400} sx={{fontSize:"14px",lineHeight:"22px"}}>Add/Edit your users and organization</Typography>
       

                </View>
                <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
          <div style={{height:3,width:3}}>  <img src={profile.default} style={{height:"56px",borderRadius:"8px"}}  /></div>
          </Grid>
          <Grid item xs={8}>
          <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography fontWeight={700} sx={{ fontSize: "14px",  font:"Inter",fontWeight:700}}>Profile Manager</Typography></Grid>
              <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px", font:"Inter",fontWeight:400}}>You can add/edit your Outreach Workers</Typography></Grid>
            </Grid>
          </Grid>
          <Grid data-test-id="openModalButton" onClick={()=>this.openModal()} item xs={2}>
            <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={settings.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>

         <Grid container style={{marginTop:10,padding:10}}>
          <Grid item xs={2} >
            <img src={editOrg.default} style={{height:"56px",borderRadius:"8px"}}  />
          </Grid>
          <Grid item xs={8}>
            <Grid container style={{paddingLeft:15}} justifyContent={"center"} alignItems={"center"}>
              <Grid item xs={12}><Typography fontWeight={700} sx={{ fontSize: "14px", font:"Inter",}}>Edit Organization</Typography></Grid>
              <Grid item xs={12}><Typography fontWeight={400}  sx={{ fontSize: "12px",font:"Inter",}}>You can edit and manage your Organization</Typography></Grid>
            </Grid>
          </Grid>
          <Grid data-test-id="openEditModalButton" item xs={2} onClick={()=>this.getAllServices()}>
            <div style={{height:3,width:3}}>   <img style={{height:"56px"}} src={settings.default} alt="mainImg" /></div>
       
          </Grid>
         </Grid>
               
              </View>
            </Grid>:null}
            {this.showAddForm()}
           
            {this.showEditForm()}
            
          </Grid>
        </View>
      </Grid>
      <Grid sx={{
        height: { md: deviceHeight, l: deviceHeight }, backgroundColor: '#333',
        color: 'white',
        padding: '20px',
        textAlign: 'center',
        justifyContent: "center",
        alignItems: "center",
        textAlignVertical: "center",
        alignContent: "center"
      }} item xs={12} md={4}>

      {this.renderEditModal()}
      {this.renderModal()}
   
      <Dialog data-test-id={"btnModalClose3"}
    open={this.state.openEditOrg}
    onClose={()=>this.handleCloseOrg()}
    maxWidth="xs"
    fullWidth
    sx={{"& .MuiPaper-root": {width: 'calc(100% - 40px)',margin:"10px",borderRadius: "12px", }}}
  >
    <DialogTitle sx={{backgroundColor:"#F6F6F3",padding: "0px 17px"}}>
      <IconButton  data-test-id={"btnModal3"}
        onClick={()=>this.setState({openEditOrg:false})}
        sx={{  left: "90%", top: "1%",alignSelf:"left" }}
      >
        <CloseIcon sx={{fill:"#2D2A24",height:"20px",width:"20px"}}/>
      </IconButton>
    </DialogTitle>

    <DialogContent sx={{ textAlign: 'left', backgroundColor:"#F6F6F3"}}>
    
        
         
       
        <Typography fontWeight={700} sx={{fontSize:"24px",lineHeight:"32px",marginBottom:2}}>Edit Organization</Typography>
     
                      <Box sx={{backgroundColor:"white",padding:1,borderRadius: "8px", }}>
                        <Grid sx={{borderRadius: "8px", }} >
                        <Typography sx={[{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Organization Name </Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",marginY:1}]}>{this.state.orgnization.organization_name}</Typography>
                        <Typography sx={[{fontWeight:"400",fontSize:"16px",font:"Inter"}]}>Organization Address</Typography>
                        <Typography sx={[styles.selectedValue,{fontWeight:"700",fontSize:"16px",marginY:1}]}>{this.state.orgnization.address}</Typography>
                        <Typography sx={[{marginTop:2,color:"#2D2A24",fontWeight:"700",fontSize:"16px",font:"Inter"}]}>Contact Person </Typography>
                        <FormControl variant="outlined" fullWidth>
                        <Input
                        type="text"
                        disableUnderline
                        value={this.state.orgnization.contact_person}
                        onChange={(e) => this.setState((prevState) => ({
                          orgnization: {
                            ...prevState.orgnization, 
                            contact_person: e.target.value,  
                          },
                        }))}
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid ', borderColor:  "#ccc",marginTop:1,
                        }}
                        data-test-id="txtInputlast1"
                       />
                       </FormControl>
                        <Typography sx={[{marginTop:1,color:"#2D2A24",fontWeight:"700",fontSize:"16px",font:"Inter"}]}>Contact Phone </Typography>
                       <FormControl variant="outlined" fullWidth>
                        <Input
                        type="text"
                        disableUnderline
                        value={this.state.orgnization.organization_phone}
                        onChange={(e) => this.setState((prevState) => ({
                          orgnization: {
                            ...prevState.orgnization, 
                            organization_phone: e.target.value,  
                          },
                        }))}
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid ', borderColor:  "#ccc",marginTop:1,
                        }}
                        data-test-id="txtInputlast2"
                       />
                       </FormControl>
                       <Grid>
                        <Typography sx={[{marginTop:1,color:"#2D2A24",fontWeight:"700",fontSize:"16px",font:"Inter"}]}>Contact Email</Typography>
                        <FormControl error={this.state.errorEmail} variant="outlined" fullWidth>
                        <Input
                        type="text"
                        disableUnderline
                        value={this.state.orgnization.organization_email}
                        onChange={(e) => this.setState((prevState) => ({
                          orgnization: {
                            ...prevState.orgnization, 
                            organization_email: e.target.value,  
                          },errorEmail:false
                        }))}
                        
                        sx={{
                          backgroundColor: "white", borderWidth: 1, borderRadius: "8px", padding: '10px', height: "44px",
                          border: '1px solid ', borderColor:  "#ccc",marginTop:1,
                        }}
                        data-test-id="txtInputlast3"
                        onBlur={() => this.handleOrgBlur()}
                        />
                        {this.showEmailError()}
                       </FormControl>
                       </Grid>
                        </Grid> 
                      </Box>

                      <View style={{backgroundColor:"#D9D9D9",height:"2px",marginVertical:5}} />
                      <Typography sx={[styles.selectedTitle,{fontWeight:"700",fontSize:"18px",font:"Inter"}]}>Services Offered </Typography>
                      
                      <Box sx={{backgroundColor:"white",margin:0.5,padding:1,borderRadius: "8px", }}>
                     
                     
                    
{this.state.allServices?.map((item, index) => (
                        <View data-test-id="View">
                       
                       {item.attributes.name !== "Other Services Offered" ?
                     <Box>
                       <Typography sx={[{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{item.attributes.name}</Typography>
                       <FormControl variant="outlined" fullWidth>
                       <Select
            multiple
            value={this.state.selectedItems}
            // onChange={this.handleSelectChange}
            displayEmpty
            sx={{
              backgroundColor: "white", borderWidth: 4, borderRadius: "8px",paddingBottom: '10px', paddingTop: '10px', height: "44px",
              borderColor:  "red",marginTop:1,marginBottom:3, '& .MuiInputBase-root': {
border: '2px solid red',  
borderRadius: '8px',      
},
'& .MuiInputBase-input': {
padding: '0px 10px', 
},
            }}
            // onClose={(this.updateEstimatedTotal)}
          data-test-id="SelectItem"
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
            }}
            renderValue={() => 'Substance Use Care / Harm Reduction Services'
            }
            style={webStyles.selectMenu}
          >
            {item.attributes.sub_services?.map((option:any) => (
             
             
              <MenuItem key={option.name} value={option.name}
              style={{
                ...webStyles.checkBoxMenu,
                borderBottom:  '1px solid #CBD5E1',
               
              }}
            >
                <Box  sx={[webStyles.itemsMainBox,{ marginLeft:3, width: "100%"}]} key={option.name} >
                  <Box sx={[webStyles.checkboxContainer,]}>
                    <Checkbox
                    data-test-id="checkBoxId"
                      edge="start"
                      checked={option.checked}
                      tabIndex={-1}
                      disableRipple
                      onClick={() => this.handleToggle(item,option)}  
                      sx={{
                        color: "#64748B",width:"10%",
                        borderRadius: "6px",
                        '&.Mui-checked': {
                          color: "#CF9500",
                        },
                      }}
                    />
                    <Typography sx={{ textAlign: "left"}} style={webStyles.listItemName}>{option.name}</Typography>
                  </Box>
                 
                
                    
                </Box>
              
               
              </MenuItem>
             
            ))}
          </Select>
                   </FormControl>

               </Box>  :
         <View>
         <Typography sx={[{fontWeight:"700",fontSize:"16px",font:"Inter"}]}>{item.attributes.name}</Typography>
         {item.attributes.sub_services?.map((option:any) => (
           <Grid container flexDirection={"row"} >
           <Box data-test-id="checkBoxId1" sx={[webStyles.itemsMainBox,{borderColor:  "#ccc",borderWidth: 1, borderRadius: "8px"}]} >
           <Box sx={webStyles.checkboxContainer}>
                       <Checkbox
                       data-test-id="checkBoxId12"
                         edge="start"
                         checked={option.checked}
                         tabIndex={-1}
                         disableRipple
                         onClick={()=>this.handleToggle(item,option)}  
                         sx={{
                           color: "#64748B",
                           borderRadius: "6px",
                           '&.Mui-checked': {
                             color: "#CF9500",
                           },
                         }}
                       />
                       <Typography sx={[webStyles.listItemName,{marginTop:"11px"}]}>{option.name}</Typography>
                       </Box></Box>
                     </Grid>))}
                     </View>      }
                        </View>
                      ))}
          
                        </Box>

                      <Button sx={[{paddingLeft:"45%",paddingRight:"42%",marginTop:1.5,marginBottom:1.5,
                      textTransform: 'none', backgroundColor: "#F5C502", color: "black", font: "Inter", fontSize: "16px", fontWeight: "700",
                      height: "44px",
                      '&:hover': {
                        backgroundColor: '#E2E8F0',
                      },
                    }]}
                      data-test-id={"btnEdit"}
                      disabled={!this.state.selectedResult}
                      onClick={()=>this.updateOrganization()}
                    >Save</Button>
        
      </DialogContent>
      </Dialog>
      </Grid>
    </Grid>
    </NavigationMenu>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  modalHeading: {
    width:"80%",
    fontWeight: 700,
    color:"#2D2A24",
    fontSize:"24px", 
  },
  modalOverlay: {
    position: "fixed" as "fixed",  
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  modalContent: {
    backgroundColor: "white",
    padding: "20px",
    width: "80%",
    maxWidth: "500px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    maxHeight: "80vh",
    overflowY: "auto" as "auto",
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  goBackButton: {
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'none',
    gap:"8px",
  },
  goBackText: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#1D4ED8',
    lineHeight: '22px',
  },
  comunityType: {
    color:"#2D2A24",
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing:"-0.05%",
    marginTop:"16px"
  },
  areaText: {
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    margin:"32px 0 8px 0"
  },
  inputDefaultLabel: {
    color:"#94A3B8",
    fontSize:"16px",
    fontWeight:400
  },
  formControl: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    border: "1px solid #CBD5E1",
    height:"44px",
  },
  menuItems: {
    backgroundColor: '#ffffff',
    borderBottom: "1px solid #CBD5E1",
    color:"#2D2A24",
    fontSize:"14px",
    fontWeight:700,
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  arrowIcon: {
    fontSize:"14px",
    fontWeight:400,
    color:"#2D2A24"
  },
  menuItemsSecond: {
    fontWeight:700,
    backgroundColor: '#ffffff',
    fontSize:"14px",
    color:"#2D2A24",
    '&:hover': {
                background: '#FFFDE7', 
              },
  },
  mapButton: {
    height:"44px",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    color:"#2D2A24",
    fontSize: '16px',
    fontWeight: 700,
    textTransform:"capitalize",
    borderRadious:"8px",
    marginTop:"32px",
  },
  submitButton: {
    marginTop:"24px",
    justifyContent:"center",
    height:"44px",
    textTransform:"capitalize",
    backgroundColor: '#F5C502',
    display:"flex",
    alignItems:"center",
    color:"#2D2A24",
    fontSize: '16px',
    borderRadious:"8px",
    fontWeight: 700,
  },
  listItemName: {
    fontWeight:700,
    fontSize:"14px",
    color:"#2D2A24",
  },
  listItemQuantity: {
    fontWeight:400,
    fontSize:"16px",
    color:"#2D2A24",
  },
  itemsMainBox: {
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%"
  },
  checkboxContainer: {
    justifyContent:"space-between",
    display:"flex",
    alignItems:"center",paddingLeft:-15
  },
  checkboxContainer1: {
    justifyContent:"space-between",
    display:"flex",
    alignItems:"center",
  },
  selectMenu: {
    height: "44px",
    color: "#94A3B8",
    fontSize: "16px",
    fontWeight: 400,
  },
  estimatedItems: {
    color:"#2D2A24",
    fontWeight:400,
    fontSize:"16px",
  },
  estimatedBox: {
    height:"44px",
    justifyContent:"space-between",
    alignItems:"center",
    display:"flex",
    marginTop:"8px",
    padding:"0px 32px"
  },
  totalItems: {
    fontWeight:700,
    fontSize:"16px",
    color:"#2D2A24",
  },
  uploadButtonBox: {
    padding: '8px',
    height:"64px",
    borderRadious:"8px",
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  photoCamera: {
    height: "48px",
    width: "48px",
    borderRadius: "8px",
    backgroundColor: '#F5C502'
  },
  cameraIcon: {
    color: "#2D2A24",
    fontSize: "24px"
  },
  addPictureText: {
    marginLeft: '16px',
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:400
  },
  pendingImageBox: {
    height: "48px",
    width: "48px",
    borderRadius: "6px",
    backgroundColor: '#FFFDC1'
  },
  defaultImageIcon:{
    color:"#F5C502",
    fontSize: "20px"
  },
  uploadButton: {
    backgroundColor: '#F5C502',
    height:"44px",
    color:"#2D2A24",
    fontSize:"16px",
    fontWeight:700,
    textTransform:"capitalize",
  },
  checkBoxMenu: {
    height: "44px",
  },
  serviceSelect: {
    height:"44px",
    fontSize:"16px",
    fontWeight:700,
  },
  umbrellaImageBox: {
    backgroundColor: "white",
    display: 'flex',
    width: "100%"
  },
  umbrellaImage: {
    width: "375px",
    height: "240px",
    padding: "20px",
    alignSelf: "center"
  },
  addPictureHeadingText: {
    fontSize: "24px",
    fontWeight: 700,
    color: "#2D2A24"
  },
  tableContainerBox: {
    boxShadow: 'none',
    borderRadius: '8px',
    border: '1px solid #CBD5E1',
  },
  addPictureIcon: {
    position: 'absolute',
    right: "4.51px",
    top: "4.51px"
  },
  footerGridBox: {
    height: "auto",
    backgroundColor: '#333',
    color: 'white',
    padding: '20px',
    textAlign: 'center',
    justifyContent: "center",
    alignItems: "center",
    textAlignVertical: "center",
    alignContent: "center"
  },
  footerLogo: {
    width: "190.02px",
    height: "26.47px",
    backgroundColor: "#F5C502",
    borderRadius: 20,
    marginLeft: 10, 
    margin: 5,
    paddingLeft: 1, 
    marginBottom: 5
  },
  footerView: {
    height: 1, 
    backgroundColor: "#F5C502", 
    paddingHorizontal: 20
  }
};
const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica",
    marginHorizontal:15
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px"
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent"
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  footerText: {
    textAlign: "center",
   
    margin: 1
  },
  headerLogo: {
    width: "122.32px",
    height: "28px",
    backgroundColor: "#F5C502",
    borderRadius: 13,
    marginRight: 10
  },
  modal:{
    position: 'absolute',
  top: '10%',
  margin:3.5,
  backgroundColor:"#F6F6F3",
  padding:2,
  maxHeight: "80%", 
  
  },
  input:{
    flexDirection:"row",justifyContent:"space-between", shadowColor: '#FCE38B',
    shadowOpacity: 0.5,
    shadowOffset: { width: 0, height: 0 }, 
    shadowRadius: 10,  
    elevation: 8, 
    borderWidth: 1,
    borderColor: '#D97706', 
    borderRadius: 10,alignContent:"center",alignItems:"center",padding:2,backgroundColor:"white"
     },
    selectedTitle:{marginLeft:1,marginTop:1,color:"#2D2A24"},
    selectedValue:{marginLeft:2,color:"#565047",marginBottom:2},
    subText:{marginTop:1,marginBottom:1},
    edit1:{
      display: "flex",
      paddingHorizontal: 10
    },
    addui:{display: "flex",padding: 10}
 
});


// Customizable Area End
