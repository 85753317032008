import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: HTMLElement | null,
  role: string;
  firstName:string;
  lastName:string;
  token:string;
  orgnizationData:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
   
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseData),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationRaiseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTermAndConditionMessage),
      getName(MessageEnum.SessionResponseRole)
    ];

    this.state = {
      anchorEl: null,
      role:"",
      firstName:"",
      lastName:"",
      token:"",
      orgnizationData:""
    };
    this.getRole();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) { 
      switch (apiRequestCallId) {
      case this.apiGetOrgCallId:
        if(responseJson.data){
        this.setState({orgnizationData:responseJson.data.attributes.organization_name})
        }
        else if(responseJson.errors[0].token){
          localStorage.removeItem('isLoggedIn')
          localStorage.setItem('isLoggedIn', 'false')
          this.props.navigation.navigate("EmailAccountLoginBlock", { replace: true });
        }
        break;
  
        
      default:
    
        break;
    }}
    // Customizable Area End
  }

  // Customizable Area Start
  apiGetOrgCallId: string="";
  async componentDidMount() {
    super.componentDidMount();
    window.history.pushState(null, document.title, window.location.href);
    const preventBack = () => {
      window.history.pushState(null, document.title, window.location.href);
    };
    window.addEventListener('popstate', preventBack);
 this.getRole();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getRole();
      });
    }
   
  }
  async componentWillUnmount() {
    const preventBack = () => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.removeEventListener('popstate', preventBack);
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorEl: event.currentTarget,
  })}
  
  handleClose = () => {
    this.setState({
      anchorEl: null, 
    });
  };
  goToPage(page:string) {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  }
  getRole = async () => {
    const country = await getStorageData("role")
    const firstName = await getStorageData("firstName")
    const lastName = await getStorageData("lastName")
    const token = await getStorageData("token")
    this.setState({role:country,firstName:firstName,lastName:lastName,token:token},()=>this.getOrganiszation())
  };
  getOrganiszation = () => {
   
    const header = {
      token:this.state.token
    }



    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetOrgCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrgApiENdPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodapi
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End
}
