import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingCSV: boolean;
  loadingJson: boolean;
  token: string;
  startDate:  Date | undefined;
  endDate:  Date | undefined;
  activePicker: string;
  openModal:boolean;
  selectedService: string[], 
  services:{name:string,checked:boolean}[],
  isSpinnerShowing:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImportExportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  jsonDataApiCallId: string = "";
  extractDataApiCallId: string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loadingCSV: false,
      loadingJson: false,
      token: "",
      startDate: new Date(),
      endDate: new Date(),
      activePicker:"start",
      openModal: false,
      selectedService: [], 
      services : [
        {name:"Completed Clean-up or Request Clean-up",checked:false},
         {name:"Harm Reduction Supplies Distribution",checked:false},
        {name:"Referrals and Warm Hand Offs",checked:false},
        {name:"Performed and Facilitated Care",checked:false},
      ],
      isSpinnerShowing:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.jsonDataApiCallId !== "" &&
      this.jsonDataApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.errors) {
        console.log(responseJson.errors);
        alert(configJSON.errorMsg);
        this.setState({ loadingJson: false });
      } else {
        responseJson && this.exportFile(JSON.stringify(responseJson), ".json");
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.extractDataApiCallId !== "" &&
      this.extractDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.errors) {
          this.showAlert("Alert", "Something went wrong.");
        }
      else {
        responseJson.download_urls.forEach((url: string, index: number) => {
          setTimeout(() => {
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${this.state.selectedService}_${index + 1}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }, index * 1000); 
        });
        this.setState({isSpinnerShowing:false})
      }     
      
    }
    

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isJSON = (text: any) => {
    try {
      var json = JSON.parse(text);
      return typeof json === "object";
    } catch (error) {
      return false;
    }
  };

  exportFile = (data: any, type: string) => {
    const blob = new Blob([data], {
      type: type === ".json" ? "application/json" : "text/csv",
    });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = `${Date.now()}${type}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.setState({ loadingCSV: false, loadingJson: false });
  };

  downloadCSVData = async () => {
    // This function uses fetch rather than RunEngine for the network request by design
    // It is done because the RestApiClientBlock does not handle CSV responses

    this.setState({ loadingCSV: true });
    try {
      const response = await fetch(
        `${configJSON.baseURL}/${configJSON.exportDataEndpoint}`,
        {
          headers: {
            "Content-Type": configJSON.csvApiContentType,
            token: this.state.token,
          },
        }
      );
      const res = await response.text();
      let result = res && this.isJSON(res) ? JSON.parse(res) : null;
      if (result?.errors) {
        console.log(result.errors);
        this.showAlert("Error downloading CSV", configJSON.errorMsg);
        this.setState({ loadingCSV: false });
      } else {
        this.exportFile(res, ".csv");
      }
    } catch (e) {
      console.log("error: ", e);
      this.setState({ loadingCSV: false });
    }
  };

  downloadJsonData = () => {
    this.setState({ loadingJson: true });
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.state.token,
    };

    const getJsonMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jsonDataApiCallId = getJsonMessage.messageId;

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exportDataEndpoint
    );

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exportDataMethod
    );

    runEngine.sendMessage(getJsonMessage.id, getJsonMessage);
    return true;
  };

  

  async extractData() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      token: await getStorageData("token"),
    };
    console.log("header",header.token)

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.extractDataApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.extractDataURL
    );

    console.log(this.state.token);

    const httpBodyData = {
      "from_date": this.state.startDate?.toISOString().split('T')[0],
      "to_date": this.state.endDate?.toISOString().split('T')[0],
      "service_types": this.state.selectedService,
      };

      console.log("2",this.state.token)

    const httpBody = {
      data_extract: httpBodyData
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleStartDateChange = (startDate: Date | null) => {
    this.setState({ startDate: startDate ?? undefined }); 
    this.setState({activePicker:"dateRange"});
  };
  
  handleEndDateChange = (endDate: Date | null) => {
    this.setState({ endDate: endDate ?? undefined }); 
    this.setState({activePicker:"dateRange"});
  };

  handleActivePicker = (val: string) =>{
    this.setState({activePicker:val})
  }

  toggleModal = () => {
    this.setState(prevState => ({
      openModal: !prevState.openModal,
    }));
  };

  exportData = () => {
    this.setState({isSpinnerShowing:true})
    this.extractData();
    this.toggleModal();
  };

  handleGoBack = () => {
    window.history.back();
  };
  handleCheckboxChange = (service:{name:string,checked:boolean}) => {
    this.setState((prevState: Readonly<{ services: { name: string; checked: boolean }[]; selectedService: string[] }>) => {
      const updatedServices = prevState.services.map((s) =>
        s.name === service.name ? { ...s, checked: !s.checked } : s
      );
  
      const updatedSelectedServices = service.checked
        ? prevState.selectedService.filter((name) => name !== service.name) 
        : [...prevState.selectedService, service.name]; 
  
      return {
        ...prevState,
        services: updatedServices,
        selectedService: updatedSelectedServices,
      };
    });
  };
  // Customizable Area End
}
