import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  helpCentreQA: any;
  token: String;
  queue_type: any;
  dataQA: [];
  dataSub: [];
  error:boolean,
  isSubmitted: boolean;
  formValues:{
    full_name:string ,
    email :string,
    message:string ,
  },
  email:string,
  full_name:string,
  message:string ,
  emailMessage:string,
  errorname:boolean,
  nameErrormsg:string,
  errormessage:boolean,
  msgerrormsg:string

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HelpCentreController extends BlockComponent<Props, S, SS> {
  getHelpCentreApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      isSubmitted: true,
      helpCentreQA: [],
      token: "",
      queue_type: "",
      error:false,
      dataQA: [],
      dataSub: [],
     formValues:{ full_name: '',
      email: '',
      message: '',},
      emailMessage:"",
      full_name: '',
      email: '',
      message: '',
      errorname:false,
      nameErrormsg:"",
      errormessage:false,
      msgerrormsg:""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recieved", JSON.stringify(message));

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }
   
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const subData = message.getData(
        getName(MessageEnum.HelpCentreMessageData)
      );
      if (subData?.que_title) {
        this.setState({ dataSub: subData.que_array ?? [] });
      } else {
        this.setState({ dataQA: subData?.que_array ?? [] });
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId === this.getHelpCentreApiCallId) {
        if (responseJson != null) {
          if (!responseJson.errors) {
            console.log(responseJson, "HelpCentre responseJson");
            this.setState({ helpCentreQA: responseJson.data,isSubmitted:false });
            this.clearFieldHandler()
            
          } 
        }
       
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goBackHandler=()=>{
  this.setState({
    isSubmitted:true,
    
  })
 }
 clearFieldHandler=()=>{
  this.setState({
    formValues:{ full_name: '',
      email: '',
      message: '',}
  })
 }
 handleGoBack = () =>{
  this.clearFieldHandler()
  window.history.back();
}
  getHelpCentreQA = (token: String) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHelpCentreApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.helpcentreAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  gotoSubScreen(item: any) {
    const que_type = item?.attributes?.que_type;
    const data = item?.attributes?.question_sub_types?.data;

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "HelpCentreSub");

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const helpcentreSubInfo = {
      que_title: que_type,
      que_array: data,
    };

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.HelpCentreMessageData),
      helpcentreSubInfo
    );

    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg);
  }

  gotoHelpCentreQA(sub_type: any, data: []) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "HelpCentreQA");

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const helpcentreSubInfo = {
      sub_type: sub_type,
      que_array: data,
    };

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.HelpCentreMessageData),
      helpcentreSubInfo
    );

    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg);
  } 
submit=()=>{
  this.handleBlurEmail()
  this.handleBlurFullName()
  this.handleBlurMessage()
  if(!this.state.error||!this.state.errorname||!this.state.errormessage){
    this.handleSubmit()
  }
}
  handleSubmit = async () => {
   
    if(this.state.error||this.state.errorname||this.state.errormessage){
      console.log(this.state.error)
    }else{
    const payload = {
      "contact": {
        "full_name": this.state.full_name,
        "email": this.state.email,
        "message": this.state.message
      }
    }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHelpCentreApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  }

  validation=(name:string,touched:any,errors:any)=>{
    return touched[name]&&Boolean(errors[name])
  }
  validationemail=(name:string,touched:any,errors:any)=>{
    return touched[name]&&Boolean(errors[name])
  }
  validationmsg=(name:string,errors:any)=>{
    return Boolean(errors[name])
  }
handleBlurEmail=()=>{
  console.log(this.state.email.length)
  const emailRegex = /^(?!.*\.\.)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+$/;
    if(this.state.email.length<=0){
      this.setState({error:true,emailMessage: "Email is required." })
    }else if (!emailRegex.test(this.state.email)) {
      this.setState({ error: true, emailMessage: "Please enter a valid email address." });
    }else{
      this.setState({ error: false})
    }
}
handleChangeemail=(email:string)=>{
this.setState({email:email,error:false,emailMessage:""})
}
handleBlurFullName=()=>{
  console.log(this.state.full_name.length)
  
    if(this.state.full_name.replace(/\s/g, "").length<=0){
      this.setState({errorname:true,nameErrormsg: "Full name is required." })
    }else{
      this.setState({ errorname: false})
    }
}
handleChangeFullname=(name:string)=>{
 
this.setState({full_name:name,errorname:false,nameErrormsg:""})
}
handleBlurMessage=()=>{
  
  
    if(this.state.message.length<=0){
      this.setState({errormessage:true,msgerrormsg: "Message is required." })
    }else{
      this.setState({ errormessage: false})
    }
}
handleChangeMessage=(msg:string)=>{
this.setState({message:msg,errormessage:false,msgerrormsg:""})
}
  // Customizable Area End
}
